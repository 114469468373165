<template>
    <ul id="status-bar">
        <li class="blue hide-responsive">
            <em>5</em> <span>aktuelle Vorträge</span>
        </li>
        <li class="green icon-chat dropdown">
            <em>3</em> <span class="hide-responsive">neue Nachrichten</span>
            <div class="dropdown-list">
                <div class="dropdown-inner">
                    <ul>
                        <li>
                            <div class="chat-message">
                                <div class="avatar">
                                    <div class="img">
                                        <img
                                            src="img/placeholder-avatar.jpg"
                                            alt="">
                                    </div>
                                </div>
                                <div class="subject">
                                    <strong>Sabrina Setlur</strong>
                                    innogy
                                </div>
                                <em>2</em>
                            </div>
                        </li>
                        <li>
                            <div class="chat-message">
                                <div class="avatar">
                                    <div class="img">
                                        <img
                                            src="img/placeholder-avatar.jpg"
                                            alt="">
                                    </div>
                                </div>
                                <div class="subject">
                                    <strong>Sabrina Setlur</strong>
                                    innogy
                                </div>
                                <em>2</em>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "StatusBar"
})
</script>

<style scoped>

</style>