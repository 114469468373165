
import FairComposition from "@/compositions/FairComposition";
import Fair from "@/models/Fair";
import FairProfile from "@/models/FairProfile";
import {Ref} from "@vue/reactivity";
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
    name: "Booth",
    emits: [
        'mounted'
    ],
    setup(props, {emit}) {
        const {profileSlug, toggleProfile} = FairComposition()


        const boothContainer: Ref<HTMLElement | null> = ref(null)

        const mounted = onMounted(() => {
            if (boothContainer.value) {
                emit('mounted', boothContainer.value?.getClientRects()[0].height);
            }
        })

        return {
            profileSlug,
            toggleProfile,

            boothContainer,
            mounted
        }
    },
    computed: {
        fair(): Fair {
            return this.$store.getters['fairs/current'];
        }
    }
})
