<template>
    <div
        id="toolbar"
        :style="toolbarStyle">
        <div class="hamburger">
            <a
                id="hamburger"
                role="button"
                href=""
                :class="{open: $route.name === 'fairInfo'}"
                title="Messeinformationen öffnen"
                @click.prevent="$emit('open-click', $event)">
                <span class="burger">
                    <em class="top" />
                    <em class="middle" />
                    <em class="bottom" />
                </span>
            </a>
        </div>

        <div
            id="test-div"
            class="slider">
        </div>

        <div class="filter hide-responsive">
            <div
                ref="fairContainer"
                class="select dropdown up"
                :class="{open: fairCategorySelectOpen === true}">
                <div
                    class="select-label"
                    @click="toggleFairCategories">
                    {{ fairCategoryTitle }}
                </div>
                <div class="dropdown-list">
                    <div class="dropdown-inner">
                        <ul>
                            <li
                                v-for="category in fairCategories"
                                :key="category.id">
                                <div class="form-item checkbox">
                                    <input
                                        :id="'fairCategory' + category.id"
                                        :checked="isChecked('fairCategories', category.id)"
                                        type="checkbox"
                                        @input="addCategoryFilter('fairCategories', category.id)">
                                    <label :for="'fairCategory' + category.id">{{ category.title }}</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <ul>
                <li
                    v-if="fair.hasVideo"
                    class="tooltip hide-responsive"
                    :class="{active: hasFilter('hasVideo')}"
                    data-tooltip="Filtern Sie Aussteller, die Ihnen mit einem Videoanruf zur Verfügung stehen.">
                    <button
                        class="icon-video"
                        @click="toggleFilter('hasVideo')" />
                </li>
                <li
                    v-if="fair.hasChat"
                    class="tooltip hide-responsive"
                    :class="{active: hasFilter('hasChat')}"
                    data-tooltip="Filtern Sie Aussteller, die Ihnen mit einem Chat zur Verfügung stehen.">
                    <button
                        class="icon-chat"
                        @click="toggleFilter('hasChat')" />
                </li>

                <li
                    ref="jobContainer"
                    class="tooltip dropdown up no-arrow hide-responsive"
                    data-tooltip="Filtern Sie Aussteller, die interessante Stellenangebote für Sie anbieten."
                    :class="{open: categoryDropDownOpen === true}">
                    <button
                        class="icon-jobs"
                        @click="toggleJobCategories" />
                    <div class="dropdown-list">
                        <div class="dropdown-inner">
                            <ul>
                                <li
                                    v-for="category in filteredJobCategories"
                                    :key="category.id">
                                    <div class="form-item checkbox">
                                        <input
                                            :id="'jobCategory' + category.id"
                                            :checked="isChecked('jobCategories', category.id)"
                                            type="checkbox"
                                            @input="addCategoryFilter('jobCategories', category.id)">
                                        <label :for="'jobCategory' + category.id">{{ category.title }}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>

                <li
                    class="dropdown up no-arrow hide-desktop"
                    :class="{open: mobileOpen === true, active: hasAnyFilter}">
                    <button
                        class="icon-gear"
                        @click="mobileOpen = !mobileOpen" />
                    <div class="dropdown-list">
                        <div class="dropdown-inner">
                            <span v-if="fair.hasVideo || fair.hasChat">Angebote</span>
                            <ul>
                                <li
                                    v-if="fair.hasVideo"
                                    class="icon-video"
                                    :class="{active: hasFilter('hasVideo')}"
                                    @click="toggleFilter('hasVideo')">
                                    Aussteller mit Videoanruf anzeigen
                                </li>
                                <li
                                    v-if="fair.hasChat"
                                    class="icon-chat"
                                    :class="{active: hasFilter('hasChat')}"
                                    @click="toggleFilter('hasChat')">
                                    Aussteller mit Chat anzeigen
                                </li>
                            </ul>

                            <span>Kategorien</span>
                            <ul>
                                <li
                                    v-for="category in fairCategories"
                                    :key="category.id">
                                    <div class="form-item checkbox">
                                        <input
                                            :id="'fairCategory-mobile-' + category.id"
                                            :checked="isChecked('fairCategories', category.id)"
                                            type="checkbox"
                                            @input="addCategoryFilter('fairCategories', category.id)">
                                        <label :for="'fairCategory-mobile-' + category.id">{{ category.title }}</label>
                                    </div>
                                </li>
                            </ul>

                            <span>Stellenangebote</span>
                            <ul>
                                <li
                                    v-for="category in filteredJobCategories"
                                    :key="category.id">
                                    <div class="form-item checkbox">
                                        <input
                                            :id="'jobCategory-mobile-' + category.id"
                                            :checked="isChecked('jobCategories', category.id)"
                                            type="checkbox"
                                            @input="addCategoryFilter('jobCategories', category.id)">
                                        <label :for="'jobCategory-mobile-' + category.id">{{ category.title }}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import Fair from "@/models/Fair";
import FairCategory from "@/models/FairCategory";
import FairProfile from "@/models/FairProfile";
import JobCategory from "@/models/JobCategory";
import {isMobile} from "@/services/Utils";
import {defineComponent} from "vue";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default defineComponent({
    name: "ToolBar",
    components: {
        VueSlider
    },
    props: {
        scrollValue: {
            required: true,
            type: Number
        },
        profiles: {
            type: Array as () => FairProfile[],
            default: () => ([]),
        },
        filters: {
            type: Object,
            required: true
        }
    },
    emits: [
        'open-click', 'scroll',
        'drag-start', 'drag-end',
        'set-filter'
    ],
    data: () => ({
        categoryDropDownOpen: false,
        mobileOpen: false,
        fairCategorySelectOpen: false,
        swiperEnabled: true,
    }),
    computed: {
        toolbarStyle() {
            if (!this.fair || !this.fair.colorHex) {
                return null;
            }
            const fair = <Fair>this.fair;

            return {background: fair.colorHex};
        },
        fairCategoryTitle() {
            const categoryIds: number[] = this.filters?.fairCategories ?? [];
            if (categoryIds.length === 0) {
                return 'Alle Kategorien';
            }

            const categories: FairCategory[] = this.fairCategories;

            const categoryNames = categories
                .filter((el: FairCategory) => el.id && categoryIds.includes(el.id))
                .map((el: FairCategory) => el.title);
            return categoryNames.join(', ');
        },
        hasAnyFilter(): boolean {
            const jobCategories = this.filters?.jobCategories ?? [];
            const fairCategories = this.filters?.fairCategories ?? [];
            const hasVideo = this.filters?.hasVideo ?? false;
            const hasChat = this.filters?.hasChat ?? false;

            return jobCategories.length > 0 || fairCategories.length > 0 || hasVideo === true || hasChat === true;
        },
        fair(): Fair | null {
            return this.$store.getters['fairs/current'];
        },
        fairProfiles(): FairProfile[] {
            return this.$store.getters['fair-profiles/all'];
        },
        fairCategories(): FairCategory[] {
            return this.fair ? this.fair.categories : [];
        },
        jobCategories(): JobCategory[] {
            return this.$store.getters['job-categories/all'];
        },
        filteredJobCategories(): JobCategory[] {
            const categoryIds: number[] = [];
            this.profiles.forEach((el: FairProfile) => {
                el.jobCategoryIds.forEach((id: number) => {
                    if (!categoryIds.includes(id)) {
                        categoryIds.push(id);
                    }
                })
            })

            if (categoryIds.length === 0) {
                return this.jobCategories;
            }

            return this.jobCategories.filter((el: JobCategory) => el.id && categoryIds.includes(el.id));
        }
    },
    watch: {
        profiles() {
            const enabled = document.body.scrollHeight > document.body.clientHeight;
            if (!enabled) {
                this.$emit('scroll', 0);
            }
            this.swiperEnabled = enabled;
        }
    },
    methods: {
        closeOutsideListenerFair(evt: MouseEvent) {
            const container = <HTMLElement | null>this.$refs.fairContainer;
            if (container) {
                this.checkClickOutside(evt, container, this.closeOutsideListenerFair, 'fairCategorySelectOpen')
            }
        },
        clickOutsideListenerJob(evt: MouseEvent) {
            const container = <HTMLElement | null>this.$refs.jobContainer;
            if (container) {
                this.checkClickOutside(evt, container, this.clickOutsideListenerJob, 'categoryDropDownOpen');
            }
        },
        checkClickOutside(evt: MouseEvent, container: HTMLElement, listener: any, attribute: string) {
            const target = <HTMLElement | null>evt.target ?? null;
            if (evt.target !== null && !container.contains(target)) {
                (this as any)[attribute] = false;
                document.body.removeEventListener('click', listener)
            }
        },
        toggleJobCategories() {
            this.categoryDropDownOpen = !this.categoryDropDownOpen;
            if (this.categoryDropDownOpen) {
                document.body.addEventListener('click', this.clickOutsideListenerJob);
            }
        },
        toggleFairCategories() {
            this.fairCategorySelectOpen = !this.fairCategorySelectOpen;
            if (this.fairCategorySelectOpen) {
                document.body.addEventListener('click', this.closeOutsideListenerFair);
            }
        },
        getDotSize() {
            if (window.outerWidth <= 760) {
                return [80, 40];
            }

            return [220, 40];
        },
        /**
         * Is a checkbox checked
         *
         * @param attribute
         * @param value
         */
        isChecked(attribute: string, value: number) {
            const values: number[] = this.filters[attribute] ?? [];
            return values.includes(value);
        },
        hasFilter(attribute: string) {
            return this.filters[attribute] ?? false;
        },
        addCategoryFilter(attribute: string, id: number) {
            const selected: number[] = this.filters[attribute] ?? [];
            const index = selected.findIndex(el => el === id);
            if (index === -1) {
                selected.push(id);
            } else {
                selected.splice(index, 1);
            }

            this.$emit('set-filter', {attribute, value: selected});
        },
        toggleFilter(attribute: string) {
            if (typeof this.filters[attribute] !== 'undefined' && this.filters[attribute]) {
                this.$emit('set-filter', {attribute, value: null});
            } else {
                this.$emit('set-filter', {attribute, value: true});
            }
        },
        dragStart() {
            this.$emit('drag-start');
        },
        dragEnd() {
            window.setTimeout(() => {
                this.$emit('drag-end')
            }, 1000);
        },
        scroll(value: number) {
            this.$emit('scroll', value);
        }
    }
})
</script>

<style scoped>

</style>
