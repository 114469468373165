<template>
    <div
        ref="boothContainer"
        class="booth"
        :class="{active: profile.slug && profile.slug === profileSlug}"
        @click="toggleProfile(profile)">
        <div
            class="booth-content"
            :class="{placeholder: profile.isPlaceHolder}">
            <template v-if="profile.isPlaceHolder === false">
                <div class="screen">
                    <div class="image">
                        <img
                            v-if="profile.companyLogoUrl"
                            class="company-logo-image"
                            :data-src="profile.companyLogoUrl"
                            alt="Unternehmenslogo">
                        <h2 :class="{ noimg : !profile.companyLogoUrl, long : (profile.title.length > 45 && profile.companyLogoUrl)}">
                            {{ profile.title }}
                        </h2>
                    </div>
                    <div class="shadow" />
                </div>
                <div
                    v-if="(fair.hasVideo && profile.hasVideo) || (fair.hasChat && profile.hasChat) || (fair.linkToThaff && profile.linkToThaff)"
                    class="display">
                    <div class="shadow" />
                    <ul>
                        <li
                            v-if="fair.hasVideo && profile.hasVideo"
                            class="tooltip"
                            data-tooltip="Videochat mit dem Aussteller starten"
                            @click.stop="toggleProfile(profile, 'video')">
                            <i class="blue icon-video" />
                        </li>
                        <li
                            v-if="fair.hasChat && profile.hasChat"
                            class="tooltip"
                            data-tooltip="Chat mit dem Aussteller starten"
                            @click.stop="toggleProfile(profile, 'chat')">
                            <i class="green icon-chat" />
                        </li>
                        <li
                            v-if="fair.linkToThaff && profile.linkToThaff"
                            class="tooltip"
                            data-tooltip="Alle Stellenangebote des Aussteller anzeigen"
                            @click.stop="toggleProfile(profile, 'stellen')">
                            <i class="red icon-jobs" />
                        </li>
                    </ul>
                </div>
                <div class="table">
                    <img
                        src="/gfx/table.svg"
                        alt="Tisch"
                        class="gfx-table">
                    <img
                        v-if="fair.hasChat && profile.hasChat"
                        src="/gfx/laptop.svg"
                        alt="Laptop"
                        class="gfx-laptop">
                </div>
                <div
                    class="plant"
                    :class="accessoryData.position">
                    <img
                        :src="accessoryData.file"
                        alt="Pflanze">
                </div>
                <div
                    v-if="profile.avatarUrl"
                    class="person"
                    :class="'pos-' + position">
                    <img
                        :src="profile.avatarUrl"
                        alt="Ansprechpartner">
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import FairComposition from "@/compositions/FairComposition";
import Fair from "@/models/Fair";
import FairProfile from "@/models/FairProfile";
import {AccessoryData} from "@/interfaces/types";
import {Ref} from "@vue/reactivity";
import {defineComponent, onMounted, PropType, ref} from "vue";

export default defineComponent({
    name: "Booth",
    props: {
        position: {
            type: Number,
            required: true
        },
        profile: {
            required: true,
            type: FairProfile
        },
        observer: {
            required: true,
            type: IntersectionObserver
        },
        accessoryData: {
            type: Object as PropType<AccessoryData>,
            required: true
        }
    },
    emits: [
        'mounted'
    ],
    setup(props, {emit}) {
        const {profileSlug, toggleProfile} = FairComposition()


        const boothContainer: Ref<HTMLElement | null> = ref(null)

        const mounted = onMounted(() => {
            if (boothContainer.value) {
                const content = boothContainer.value?.querySelector('.booth-content');
                if (content) {
                    props.observer.observe(boothContainer.value);
                    // props.observer.observe(content);
                }

                emit('mounted', boothContainer.value?.getClientRects()[0].height);
            }
        })

        return {
            profileSlug,
            toggleProfile,

            boothContainer,
            mounted
        }
    },
    computed: {
        getColorStyle() {
            if (!this.fair || !this.fair.colorHex) {
                return null;
            }
            const fair = <Fair>this.fair;

            return {background: fair.colorHex};
        },
        fair(): Fair {
            return this.$store.getters['fairs/current'];
        },
    },
})
</script>
