
import Fair from "@/models/Fair";
import FairCategory from "@/models/FairCategory";
import FairProfile from "@/models/FairProfile";
import JobCategory from "@/models/JobCategory";
import {isMobile} from "@/services/Utils";
import {defineComponent} from "vue";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default defineComponent({
    name: "ToolBar",
    components: {
        VueSlider
    },
    props: {
        scrollValue: {
            required: true,
            type: Number
        },
        profiles: {
            type: Array as () => FairProfile[],
            default: () => ([]),
        },
        filters: {
            type: Object,
            required: true
        }
    },
    emits: [
        'open-click', 'scroll',
        'drag-start', 'drag-end',
        'set-filter'
    ],
    data: () => ({
        categoryDropDownOpen: false,
        mobileOpen: false,
        fairCategorySelectOpen: false,
        swiperEnabled: true,
    }),
    computed: {
        toolbarStyle() {
            if (!this.fair || !this.fair.colorHex) {
                return null;
            }
            const fair = <Fair>this.fair;

            return {background: fair.colorHex};
        },
        fairCategoryTitle() {
            const categoryIds: number[] = this.filters?.fairCategories ?? [];
            if (categoryIds.length === 0) {
                return 'Alle Kategorien';
            }

            const categories: FairCategory[] = this.fairCategories;

            const categoryNames = categories
                .filter((el: FairCategory) => el.id && categoryIds.includes(el.id))
                .map((el: FairCategory) => el.title);
            return categoryNames.join(', ');
        },
        hasAnyFilter(): boolean {
            const jobCategories = this.filters?.jobCategories ?? [];
            const fairCategories = this.filters?.fairCategories ?? [];
            const hasVideo = this.filters?.hasVideo ?? false;
            const hasChat = this.filters?.hasChat ?? false;

            return jobCategories.length > 0 || fairCategories.length > 0 || hasVideo === true || hasChat === true;
        },
        fair(): Fair | null {
            return this.$store.getters['fairs/current'];
        },
        fairProfiles(): FairProfile[] {
            return this.$store.getters['fair-profiles/all'];
        },
        fairCategories(): FairCategory[] {
            return this.fair ? this.fair.categories : [];
        },
        jobCategories(): JobCategory[] {
            return this.$store.getters['job-categories/all'];
        },
        filteredJobCategories(): JobCategory[] {
            const categoryIds: number[] = [];
            this.profiles.forEach((el: FairProfile) => {
                el.jobCategoryIds.forEach((id: number) => {
                    if (!categoryIds.includes(id)) {
                        categoryIds.push(id);
                    }
                })
            })

            if (categoryIds.length === 0) {
                return this.jobCategories;
            }

            return this.jobCategories.filter((el: JobCategory) => el.id && categoryIds.includes(el.id));
        }
    },
    watch: {
        profiles() {
            const enabled = document.body.scrollHeight > document.body.clientHeight;
            if (!enabled) {
                this.$emit('scroll', 0);
            }
            this.swiperEnabled = enabled;
        }
    },
    methods: {
        closeOutsideListenerFair(evt: MouseEvent) {
            const container = <HTMLElement | null>this.$refs.fairContainer;
            if (container) {
                this.checkClickOutside(evt, container, this.closeOutsideListenerFair, 'fairCategorySelectOpen')
            }
        },
        clickOutsideListenerJob(evt: MouseEvent) {
            const container = <HTMLElement | null>this.$refs.jobContainer;
            if (container) {
                this.checkClickOutside(evt, container, this.clickOutsideListenerJob, 'categoryDropDownOpen');
            }
        },
        checkClickOutside(evt: MouseEvent, container: HTMLElement, listener: any, attribute: string) {
            const target = <HTMLElement | null>evt.target ?? null;
            if (evt.target !== null && !container.contains(target)) {
                (this as any)[attribute] = false;
                document.body.removeEventListener('click', listener)
            }
        },
        toggleJobCategories() {
            this.categoryDropDownOpen = !this.categoryDropDownOpen;
            if (this.categoryDropDownOpen) {
                document.body.addEventListener('click', this.clickOutsideListenerJob);
            }
        },
        toggleFairCategories() {
            this.fairCategorySelectOpen = !this.fairCategorySelectOpen;
            if (this.fairCategorySelectOpen) {
                document.body.addEventListener('click', this.closeOutsideListenerFair);
            }
        },
        getDotSize() {
            if (window.outerWidth <= 760) {
                return [80, 40];
            }

            return [220, 40];
        },
        /**
         * Is a checkbox checked
         *
         * @param attribute
         * @param value
         */
        isChecked(attribute: string, value: number) {
            const values: number[] = this.filters[attribute] ?? [];
            return values.includes(value);
        },
        hasFilter(attribute: string) {
            return this.filters[attribute] ?? false;
        },
        addCategoryFilter(attribute: string, id: number) {
            const selected: number[] = this.filters[attribute] ?? [];
            const index = selected.findIndex(el => el === id);
            if (index === -1) {
                selected.push(id);
            } else {
                selected.splice(index, 1);
            }

            this.$emit('set-filter', {attribute, value: selected});
        },
        toggleFilter(attribute: string) {
            if (typeof this.filters[attribute] !== 'undefined' && this.filters[attribute]) {
                this.$emit('set-filter', {attribute, value: null});
            } else {
                this.$emit('set-filter', {attribute, value: true});
            }
        },
        dragStart() {
            this.$emit('drag-start');
        },
        dragEnd() {
            window.setTimeout(() => {
                this.$emit('drag-end')
            }, 1000);
        },
        scroll(value: number) {
            this.$emit('scroll', value);
        }
    }
})
