
import {defineComponent, onBeforeMount, PropType, watch} from 'vue';
import Fair from '@/models/Fair';
import FairProfile from '@/models/FairProfile';
import {initialSetup, updateScene, updateSelectedFairProfileIndex} from '@/services/Three';
import FairComposition from '@/compositions/FairComposition';

export default defineComponent({
    name: 'ExhibitorsThree',
    emits: ['scroll'],
    props: {
        fair: {
            type: Object as PropType<Fair>,
            required: true
        },
        filteredFairProfiles: {
            required: true,
            type: Array as PropType<FairProfile[]>
        }
    },
    setup(props, {emit}) {
        const {currentProfile, toggleProfile} = FairComposition();

        const getFairIndex = (profile: FairProfile | null): number | null => {
            if (profile === null) {
                return null;
            }

            const index = props.filteredFairProfiles.findIndex(el => profile && el.id === profile.id);
            return index !== -1 ? index : null;
        };

        const options = {
            onChangeX(x: number, max: number) {
                emit('scroll', (x / max) * 100);
            },
            onClickFair(fairProfile: FairProfile, tab: string | null) {
                toggleProfile(fairProfile, tab);
            }
        };

        const onMounted = onBeforeMount(() => {
            initialSetup(props.fair, props.filteredFairProfiles, options);
            updateSelectedFairProfileIndex(getFairIndex(currentProfile.value));
        });

        watch(() => currentProfile.value, () => {
            updateSelectedFairProfileIndex(getFairIndex(currentProfile.value));
        });


        const filterCallback = (newValue: FairProfile[]) => {
            updateScene(props.fair, newValue, options);
        }
        watch(() => props.filteredFairProfiles, filterCallback, {deep: true});

        watch(props.fair, (newValue: Fair) => {
            updateScene(newValue, props.filteredFairProfiles, options);
        });

        return {
            onMounted
        };
    }
});
