
import Booth from "@/components/base/Booth.vue";
import NoBooth from "@/components/base/NoBooth.vue";
import {AccessoryData} from "@/interfaces/types";
import FairProfile from "@/models/FairProfile";
import {Ref} from "@vue/reactivity";
import {defineComponent, onMounted, onUnmounted, PropType, ref} from "vue";
import Fair from "@/models/Fair";

export default defineComponent({
    name: "Exhibitors",
    components: {
        Booth,
        NoBooth
    },
    props: {
        fair: {
            type: Fair,
            required: true
        },
        filteredFairProfiles: {
            required: true,
            type: Array as PropType<FairProfile[]>
        }
    },
    emits: [
        'booth-mounted'
    ],
    setup() {
        const positions: Ref<{ [key: string]: number }> = ref({});

        const exhibitorContainer: Ref<null | HTMLElement> = ref(null);

        const getPosition = (profile: FairProfile) => {
            const index = profile.id ?? 0;
            let position = positions.value[index] ?? null;
            if (position === null) {
                position = Math.floor(Math.random() * 3) + 1
                positions.value[index] = position;
            }

            return position;
        }

        const accessories = ref<AccessoryData[]>([]);
        const getAccessoryData = (profile: FairProfile): AccessoryData => {
            const id = profile.id ?? 0;
            const index = accessories.value.findIndex((el => el.id === id));
            let response;
            if(index  === -1){
                const random = Math.floor(Math.random() * 4 + 1)
                response = {
                    id: id,
                    position: 'pos-' + Math.floor(Math.random() * 2 + 1),
                    file: `/gfx/plant-0${random}.svg`
                };
                accessories.value.push(response)
            }else{
                response = accessories.value[index];
            }

            return response;
        }

        const observer: Ref<null | IntersectionObserver> = ref(null);
        const unmounted = onUnmounted(() => {
            if (observer.value) {
                observer.value?.disconnect()
            }
        })

        const mounted = onMounted(() => {
            if (exhibitorContainer.value) {
                const options = {
                    root: exhibitorContainer.value,
                    rootMargin: "0px",
                    threshold: 1.0
                };

                observer.value = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
                    entries.forEach((el: IntersectionObserverEntry) => {
                        const target = <HTMLDivElement>el.target;
                        if (target) {
                            const content = <HTMLDivElement>target.querySelector('.booth-content');
                            if (content) {
                                if (el.isIntersecting) {
                                    content.style.removeProperty('display');
                                    const imageLoaded = content.dataset.loaded ?? false;
                                    if(!imageLoaded){
                                        const image = content.querySelector('.company-logo-image') as HTMLImageElement;
                                        if(image && !image.src && typeof image.dataset.src === 'string'){
                                            image.src = image.dataset.src;
                                            content.dataset.loaded = "1";
                                        }
                                    }
                                } else {
                                    content.style.display = 'none';
                                }
                            }
                        }
                    })
                });
            }
        })

        return {
            // lifecycle hooks
            mounted, unmounted,

            // ref
            exhibitorContainer,

            positions,
            getPosition,
            accessories,
            getAccessoryData,

            observer
        }
    }
})
