import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
//@ts-ignore
import {Text} from 'troika-three-text';
import FairProfile from '@/models/FairProfile';
import Fair from '@/models/Fair';
import {AnimationMixer, AnimationUtils, Camera, Intersection, Matrix3, Matrix4, Mesh, Object3D, Sprite, Texture, VectorKeyframeTrack} from 'three';
import {BufferGeometry} from 'three/src/core/BufferGeometry';
import {Material} from 'three/src/materials/Material';
import {InstancedMesh} from 'three/src/objects/InstancedMesh';
import {EventListener} from 'three/src/core/EventDispatcher';
import {cloneDeep, throttle} from 'lodash';
import {isDev, isMobile} from '@/services/Utils';
import {AnimationAction} from 'three/src/animation/AnimationAction';

const zoom = 200;
let doRaycast = false;
const renderer = new THREE.WebGLRenderer({antialias: true});
const stats = Stats();
const camera = new THREE.OrthographicCamera(window.innerWidth / -zoom, window.innerWidth / zoom, window.innerHeight / zoom, window.innerHeight / -zoom, 0.01, 10000);
const controls = new OrbitControls(camera, renderer.domElement);
const scene = new THREE.Scene();
const raycaster = new THREE.Raycaster();
const clock = new THREE.Clock();
let mixer: AnimationMixer | null = null;
let clipAction: AnimationAction | null = null;

// make controls, scene, camera available in global window object in dev mode
const w = (isDev() ? window : {}) as unknown as any;
w.controls = controls;
w.scene = scene;
w.camera = camera;

let isReloading = false;

// Load Manager
const manager = new THREE.LoadingManager();
manager.onLoad = function () {
    console.log('Loading complete!');
};

manager.onError = function (url: string) {
    console.log('There was an error loading ' + url);
};

THREE.Cache.enabled = true;
// Loaders
const gltfLoader = new GLTFLoader(manager);
const bitmapLoader = new THREE.ImageBitmapLoader(manager);
const textureLoader = new THREE.TextureLoader(manager);

const mouse = new THREE.Vector2(1, 1);
const color = new THREE.Color();
// const color2 = new THREE.Color();

const companyLogoMaterial = new THREE.MeshBasicMaterial({
    color: 0xFFFFFF,
    toneMapped: false
});

const settings = {
    debug: false,
    renderLogos: true,
    showDrawCalls: false,
    analyzeDrawCalls: false,
    boothSize: 6,
    fairLogoSpacing: 1,
    fairLogoHeight: .25,
    boothSpacing: 1,
    fairColor: 0x0F94C6
};

interface RenderModels {
    name: string,
    filename: string,
    geometries: BufferGeometry[],
    materials: Material[],
    mesh?: Mesh | InstancedMesh | null,
    meshes: InstancedMesh[] | Mesh[] | null,
    scale: number,
    offsetLeft: { x: number, z: number, y?: number },
    offsetRight: { x: number, z: number, y?: number },
    rotation?: { x?: number, z?: number, y?: number },
}

interface PlantModels {
    id: number,
    filename: string,
    geometries: BufferGeometry[],
    materials: Material[],
    meshes: InstancedMesh[],
    scale: number,
}

interface CustomOptions {
    onChangeX: (x: number, max: number) => void;
    onClickFair: (profile: FairProfile, tab: string | null) => void;
}

let localFairs: FairProfile[] = [];

let models: RenderModels[] = [
    {
        name: 'screen',
        filename: '/models/screen.glb',
        geometries: [],
        materials: [],
        meshes: [],
        scale: 1,
        offsetLeft: {
            x: 56,
            z: 37,
        },
        offsetRight: {
            x: 56,
            z: 37,
        },
        rotation: {
            y: 180
        }
    },
    {
        name: 'table',
        filename: '/models/table.glb',
        geometries: [],
        materials: [],
        meshes: [],
        scale: 0.666,
        offsetLeft: {
            x: 29,
            z: 62,
        },
        offsetRight: {
            x: 29,
            z: 18,
        },
    },
    {
        name: 'stand',
        filename: '/models/stand.glb',
        geometries: [],
        materials: [],
        mesh: null,
        meshes: [],
        scale: 1,
        offsetLeft: {
            x: 33,
            z: 25,
        },
        offsetRight: {
            x: 33,
            z: 74,
        },
        rotation: {
            y: 90
        }
    },
    {
        name: 'laptop',
        filename: '/models/laptop.glb',
        geometries: [],
        materials: [],
        mesh: null,
        meshes: [],
        scale: 1.3,
        offsetLeft: {
            x: 27.5,
            y: 1.35,
            z: 62,
        },
        offsetRight: {
            x: 27.5,
            y: 1.35,
            z: 18,
        },
        rotation: {
            y: 90
        }
    },
];
const logoOffset = models.find(element => element.name === 'screen') as unknown as RenderModels;
let boothPlates: InstancedMesh | null;
let iconPlates: InstancedMesh | null;
let hoverProfileMesh: Mesh | null;
let hoverFairProfileIndex: number | null = null;

let selectedProfileMesh: Mesh | null;
let selectedFairProfileIndex: number | null = null;
const fairLoopStart = -5;

const allowedFairProfileObjectNames: string[] = [
    'companyLogoObject',
    'table',
    'screen',
    'icon-plate',
    'person',
    'booth-plate'
];

let modelLogoStand: RenderModels = {
    name: 'logostand',
    filename: '/models/logo-stand.glb',
    geometries: [],
    materials: [],
    mesh: null,
    meshes: [],
    scale: .7,
    offsetLeft: {
        x: 27,
        z: 25,
    },
    offsetRight: {
        x: 27,
        z: 74,
    },
    rotation: {}
};

THREE.Cache.enabled = true;

/**
 * initial setup function that should be called at the start once
 *
 * @param fair
 * @param fairData
 * @param options
 */
export const initialSetup = (fair: Fair, fairData: FairProfile[], options: CustomOptions) => {
    localFairs = fairData;
    renderer.physicallyCorrectLights = false;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    if (isDev()) {
        document.body.appendChild(stats.dom);
    }

    camera.position.set(-20, 20, 20);
    camera.lookAt(0, 0, 0);

    renderer.domElement.addEventListener('wheel', (e: WheelEvent) => {
        e.preventDefault();

        if (!e.ctrlKey) {
            const delta = -1 * (e.deltaY / 50);
            camera.position.setX(camera.position.x + delta);
            controls.target.setX(controls.target.x + delta);
        } else {
            camera.zoom += (-1 * e.deltaY) / 1000;
            if (camera.zoom < controls.minZoom) {
                camera.zoom = controls.minZoom;
            }
            if (camera.zoom > controls.maxZoom) {
                camera.zoom = controls.maxZoom;
            }
            camera.updateProjectionMatrix();

        }

    });

    controls.enableDamping = true;
    controls.dampingFactor = .09;
    controls.enableRotate = true;
    controls.minPolarAngle = Math.PI / 4;
    controls.maxPolarAngle = Math.PI / 2.5;
    controls.minAzimuthAngle = -Math.PI / 2.5;
    controls.maxAzimuthAngle = -Math.PI / 10;
    controls.minZoom = 0.7;
    if (isMobile()) {
        controls.minZoom = 0.5;
        camera.zoom = .5;
        camera.updateProjectionMatrix();
    }
    controls.maxZoom = 3;
    controls.panSpeed = 2;
    controls.enableZoom = isMobile();
    controls.panSpeed = 2;
    controls.mouseButtons = {
        LEFT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.PAN,
        RIGHT: THREE.MOUSE.ROTATE
    };
    controls.touches = {
        ONE: THREE.TOUCH.PAN,
        TWO: THREE.TOUCH.DOLLY_ROTATE
    };
    controls.screenSpacePanning = false;

    scene.background = new THREE.Color(0xdddddd);

    bitmapLoader.setOptions({imageOrientation: 'flipY'});

    window.addEventListener('resize', onWindowResize);
    window.addEventListener('mousemove', onMouseMove);

    const onclick = () => {
        raycaster.setFromCamera(mouse, camera);

        const intersection = getCurrentIntersection();
        if (intersection && typeof intersection.instanceId !== 'undefined') {
            let tab: string | null = null;
            let profile: FairProfile | null;
            const profileId = intersection.object?.userData?.profileId ?? null;
            if (profileId) {
                profile = localFairs.find(el => el.id && el.id === profileId) || null;
            } else {
                profile = localFairs[intersection.instanceId] ?? null;
            }
            if (profile) {
                switch (intersection.object.name) {
                    case 'companyLogoObject':
                    case 'screen':
                    case 'booth-plate':
                        tab = null;
                        break;
                    case 'icon-plate':
                        tab = intersection.object?.userData?.target ?? null;
                        //@ts-ignore
                        // intersection.object.setColorAt( intersection.instanceId, color.setHex( 0xff0000 ) );
                        //@ts-ignore
                        // intersection.object.instanceColor.needsUpdate = true;
                        break;
                }

                options.onClickFair(profile, tab);
            }
        }

    };


    let isMouseMoving = false;
    let movingCounter: number = 0;
    const mouseMove = () => {
        movingCounter++;
        if (movingCounter > 3) {
            isMouseMoving = true;
        }
    };
    renderer.domElement.addEventListener('mousedown', () => {
        isMouseMoving = false;
        movingCounter = 0;
        renderer.domElement.removeEventListener('mousemove', mouseMove);
        renderer.domElement.addEventListener('mousemove', mouseMove);
    });

    renderer.domElement.addEventListener('mouseup', function () {
        renderer.domElement.removeEventListener('mousemove', mouseMove);
        if (!isMouseMoving) {
            onclick();
        } else {
            console.log('abort... mouse move');
        }
        isMouseMoving = false;
    });

    // set hover and selected profile meshes
    const boothPlateGeometry = new THREE.PlaneGeometry(settings.boothSize, settings.boothSize, 1, 1);
    const outlineMaterial = new THREE.MeshBasicMaterial({
        color: new THREE.Color(fair.colorHex ?? settings.fairColor),
        toneMapped: false,
        opacity: .1,
        transparent: true
    });

    selectedProfileMesh = new THREE.Mesh(boothPlateGeometry, outlineMaterial);
    // setup the THREE.AnimationMixer
    selectedProfileMesh.name = 'selected-profile-mesh';
    selectedProfileMesh.rotation.x = -Math.PI / 2;
    selectedProfileMesh.position.set(
        0,
        -1,
        0,
    );

    hoverProfileMesh = new THREE.Mesh(boothPlateGeometry, outlineMaterial);
    hoverProfileMesh.name = 'hover-profile-mesh';
    hoverProfileMesh.rotation.x = -Math.PI / 2;
    hoverProfileMesh.position.set(
        0,
        -1,
        0,
    );

    scene.add(hoverProfileMesh);
    scene.add(selectedProfileMesh);

    mixer = new THREE.AnimationMixer(selectedProfileMesh);
    w.mixer = mixer;

    setupScene(fair, fairData, options);
};

const playSelectedClipAction = () => {
    if (mixer && selectedProfileMesh) {

        if (clipAction) {
            clipAction.stop();
            mixer.uncacheAction(clipAction.getClip());
            clipAction = null;
        }

        const values = [
            selectedProfileMesh.position.x, selectedProfileMesh.position.y, selectedProfileMesh.position.z,
            selectedProfileMesh.position.x, selectedProfileMesh.position.y + 0.1, selectedProfileMesh.position.z,
            selectedProfileMesh.position.x, selectedProfileMesh.position.y, selectedProfileMesh.position.z,
        ];
        const selectedMeshPositionKeyFrame = new THREE.VectorKeyframeTrack(
            '.position',
            [0, 1, 2],
            values
        );
        // create an animation sequence with the tracks
        // If a negative time value is passed, the duration will be calculated from the times of the passed tracks array
        const clip = new THREE.AnimationClip('Action', 3, [selectedMeshPositionKeyFrame]);
        clipAction = mixer.clipAction(clip);
        clipAction.play();
        w.clipAction = clipAction;
    }
};

/**
 * load all models
 *
 * @param fairData
 */
async function loadModels(fairData: FairProfile[]) {
    for (let model of models) {
        let gltf = await gltfLoader.loadAsync(model.filename);
        gltf.scene.traverse((child: Object3D) => {
            if (child instanceof Mesh) {
                let geometry = child.geometry;
                geometry.computeVertexNormals();
                model.geometries.push(geometry);
                model.materials.push(child.material);
            }
        });
        // let mergedGeometries = BufferGeometryUtils.mergeBufferGeometries(model.geometries);
        // let objectMaterial = new THREE.MeshPhongMaterial({color: 0xffffff});
        // let object = new THREE.InstancedMesh(mergedGeometries, objectMaterial, fairData.length);
        let object;
        for (let i = 0; i < model.geometries.length; i++) {
            object = new THREE.InstancedMesh(model.geometries[i], model.materials[i], fairData.length);
            object.castShadow = true;
            object.name = model.name;
            if (model.meshes) {
                model.meshes.push(object);
            }
            setupInstances(model, object, fairData);
        }
    }
}

/**
 * Create an instance for each FairProfile for a certain object (stand, screen, etc)
 *
 * @param model
 * @param mesh
 * @param fairData
 */
function setupInstances(model: RenderModels, mesh: InstancedMesh, fairData: FairProfile[]) {
    for (let i = 0; i < fairData.length; i++) {

        const dummy = new THREE.Object3D();

        let x = Math.floor(i / 2);
        let z = i % 2;

        if (typeof model.rotation !== 'undefined') {
            if (typeof model.rotation.x !== 'undefined') {
                dummy.rotation.x = THREE.MathUtils.degToRad(model.rotation.x);
            }
            if (typeof model.rotation.y !== 'undefined') {
                dummy.rotation.y = THREE.MathUtils.degToRad(model.rotation.y);
            }
            if (typeof model.rotation.z !== 'undefined') {
                dummy.rotation.z = THREE.MathUtils.degToRad(model.rotation.z);
            }
        }

        dummy.scale.set(
            model.scale,
            model.scale,
            model.scale,
        );

        if (z === 1) {
            dummy.position.set(
                x * settings.boothSize + ((model.offsetRight.x / 100) * settings.boothSize),
                (typeof model.offsetRight.y !== 'undefined') ? model.offsetRight.y : 0,
                z * settings.boothSize + ((model.offsetRight.z / 100) * settings.boothSize) + settings.boothSpacing,
            );
        } else {
            dummy.position.set(
                x * settings.boothSize + ((model.offsetLeft.x / 100) * settings.boothSize),
                (typeof model.offsetLeft.y !== 'undefined') ? model.offsetLeft.y : 0,
                z * settings.boothSize + ((model.offsetLeft.z / 100) * settings.boothSize),
            );
        }

        if (model.name === 'laptop' && fairData[i].hasVideo === false && fairData[i].hasChat === false) {
            dummy.position.y = -10;
        }

		if (model.name === 'stand' && fairData[i].hasVideo === false && fairData[i].hasChat === false && fairData[i].linkToThaff === false) {
            dummy.position.y = -10;
        }

        dummy.updateMatrix();
        mesh.setMatrixAt(i, dummy.matrix);
        mesh.setColorAt(i, color);
    }
    scene.add(mesh);
}

const init = (fair: Fair, fairData: FairProfile[]) => {
    // const performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    // alert(performance);

    loadModels(fairData);
    doRaycast = true;

    for (let i = 0; i < fairData.length; i++) {
        let x = Math.floor(i / 2);
        let z = i % 2;

        if (settings.renderLogos === true) {
            let imageUrl = fairData[i].companyLogoUrl;
            let hasLogo = false;

            if (imageUrl !== null) {
                let filetypes = imageUrl.split('.');
                const filetype = filetypes[filetypes.length - 1];

                if (filetype !== 'svg') {
                    hasLogo = true;
                    bitmapLoader.load(
                        imageUrl, (imageBitmap: ImageBitmap) => {
                            const logoTexture = new THREE.CanvasTexture(imageBitmap);
                            logoTexture.needsUpdate = false;
                            renderer.initTexture(logoTexture);
                            const logoRatio = logoTexture.image.width / logoTexture.image.height;
                            logoTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
                            logoTexture.minFilter = THREE.NearestFilter;
                            logoTexture.magFilter = THREE.NearestFilter;

                            const logoMaterial = new THREE.MeshBasicMaterial({
                                map: logoTexture,
                                toneMapped: false,
                                transparent: true
                            });

                            logoMaterial.side = THREE.FrontSide;

                            let logoGeometry;

                            if (logoRatio > 1 && logoRatio <= 1.5) {
                                logoGeometry = new THREE.BoxGeometry(1.9 / logoRatio, .01, 1.05);
                            } else if (logoRatio > 1.5) {
                                logoGeometry = new THREE.BoxGeometry(1.9, .01, 2.1 / logoRatio);
                            } else if (logoRatio < 1) {
                                logoGeometry = new THREE.BoxGeometry(1.05 * logoRatio, .01, 1.05);
                            } else if (logoRatio === 1) {
                                logoGeometry = new THREE.BoxGeometry(1.05, .01, 1.05);
                            }

                            let logo = new THREE.Mesh(logoGeometry, logoMaterial);
                            logo.userData = {
                                profileId: fairData[i].id
                            };
                            logo.name = 'logo';
                            logo.rotation.z = -Math.PI / 2;
                            logo.rotation.x = -Math.PI / 2;

                            if (z === 1) {
                                logo.position.set(
                                    x * settings.boothSize + ((logoOffset.offsetRight.x / 100) * settings.boothSize) - .015,
                                    1.7,
                                    z * settings.boothSize + ((logoOffset.offsetRight.z / 100) * settings.boothSize) + settings.boothSpacing,
                                );
                            } else {
                                logo.position.set(
                                    x * settings.boothSize + ((logoOffset.offsetLeft.x / 100) * settings.boothSize) - .015,
                                    1.7,
                                    z * settings.boothSize + ((logoOffset.offsetRight.z / 100) * settings.boothSize),
                                );
                            }

                            logo.receiveShadow = false;
                            logo.castShadow = false;
                            scene.add(logo);
                            // imageBitmap.close();
                        },
                        undefined,
                        (err: ErrorEvent) => {
                            console.log(err);
                        }
                    );
                }
            }

            const companyText = new Text();
            companyText.text = fairData[i].title;
            companyText.fontSize = 0.15;
            companyText.color = 0x435060;
            companyText.rotation.y = -Math.PI / 2;
            companyText.anchorX = '50%';
            companyText.anchorY = '50%';
            companyText.maxWidth = 2;
            companyText.textAlign = 'center';
            companyText.sync();

            if (z === 1) {
                companyText.position.set(
                    x * settings.boothSize + ((logoOffset.offsetRight.x / 100) * settings.boothSize) - .025,
                    1.3,
                    z * settings.boothSize + ((logoOffset.offsetRight.z / 100) * settings.boothSize) + settings.boothSpacing,
                );
            } else {
                companyText.position.set(
                    x * settings.boothSize + ((logoOffset.offsetLeft.x / 100) * settings.boothSize) - .025,
                    1.3,
                    z * settings.boothSize + ((logoOffset.offsetRight.z / 100) * settings.boothSize),
                );
            }

            if (hasLogo === true) {
                companyText.position.y = .8;
            }

            scene.add(companyText);
        }

        if (fairData[i].avatarUrl && fairData[i].avatarUrl !== null) {
            const map = textureLoader.load((fairData[i].avatarUrl as unknown as string));
            const material = new THREE.SpriteMaterial({
                map: map,
                toneMapped: false,
                transparent: true,
                alphaTest: .5
            });
            const sprite = new THREE.Sprite(material);
            sprite.name = 'person';
            sprite.userData = {
                profileId: fairData[i].id
            };
            sprite.rotation.x = -Math.PI / 2;
            sprite.scale.set(
                2,
                2,
                2,
            );

            const spriteOffset = models.find(element => element.name === 'table');
            if (spriteOffset) {
                const randomOffset = getRandomFloat(0.07, 0.13, 2);
                if (z === 1) {
                    sprite.position.set(
                        x * settings.boothSize + ((spriteOffset.offsetRight.x / 100) * settings.boothSize) + settings.boothSize * randomOffset,
                        1,
                        z * settings.boothSize + ((spriteOffset.offsetRight.z / 100) * settings.boothSize) + settings.boothSpacing + settings.boothSize * randomOffset,
                    );
                } else {
                    sprite.position.set(
                        x * settings.boothSize + ((spriteOffset.offsetLeft.x / 100) * settings.boothSize) + settings.boothSize * randomOffset,
                        1,
                        z * settings.boothSize + ((spriteOffset.offsetLeft.z / 100) * settings.boothSize) + settings.boothSize * randomOffset,
                    );
                }
            }
            scene.add(sprite);
        }
    }

    if (!isReloading) {
        animate();
    }
};

let limitControlHandler: EventListener<{}, 'change', OrbitControls>;

/**
 * initial setup for the scene
 *
 * @param fair
 * @param fairData
 * @param options
 */
const setupScene = (fair: Fair, fairData: FairProfile[], options: CustomOptions) => {

    if (limitControlHandler) {
        controls.removeEventListener('change', limitControlHandler);
    }

    const thottedChangeX = throttle(options.onChangeX, 300, {
        trailing: true
    });
    const limitPan = createLimitPan({camera, controls}, thottedChangeX);
    limitControlHandler = (e) => {
        limitPan({
            maxX: Math.ceil(fairData.length / 2) * settings.boothSize,
            minX: 4,
            maxZ: 8,
            minZ: 4
        });
    };

    controls.addEventListener('change', limitControlHandler);

    if (fair.imageWall) {
        textureLoader.load(fair.imageWall, (texture: Texture) => {
            const logoRatio = texture.image.width / texture.image.height;
            texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

            gltfLoader.load(modelLogoStand.filename, (gltf: any) => {


                const box = new THREE.Box3().setFromObject(gltf.scene);
                const size = box.getSize(new THREE.Vector3());
                size.x = size.x * modelLogoStand.scale;
                size.y = size.y * modelLogoStand.scale;

                const modelLogoStandCount = Math.floor(floorHeight / (size.x + settings.fairLogoSpacing));

                gltf.scene.traverse((child: Mesh<BufferGeometry, Material>) => {
                    if (child.isMesh) {
                        let geometry = child.geometry;
                        geometry.computeVertexNormals();
                        modelLogoStand.geometries.push(geometry);
                    }
                });

                let mergedGeometries = BufferGeometryUtils.mergeBufferGeometries(modelLogoStand.geometries);
                let objectMaterial = new THREE.MeshPhongMaterial({
                    color: 0xC7CDD4
                });
                let object = new THREE.InstancedMesh(mergedGeometries, objectMaterial, fairData.length + 10);

                const fairLogoMaterial = new THREE.MeshBasicMaterial({
                    map: texture,
                    opacity: .65,
                    transparent: true,
                    toneMapped: false,
                });

                const fairLogoBackgroundMaterial = new THREE.MeshBasicMaterial({
                    color: new THREE.Color(fair.colorHex ?? settings.fairColor),
                    toneMapped: false,
                });

                const fairLogoGeometry = new THREE.PlaneGeometry(size.x, size.x / logoRatio, 1, 1);
                let fairLogoObject = new THREE.InstancedMesh(fairLogoGeometry, fairLogoMaterial, modelLogoStandCount);

                const fairLogoBackgroundGeometry = new THREE.PlaneGeometry(size.x / modelLogoStand.scale, size.y / modelLogoStand.scale, 1, 1);
                let fairLogoBackgroundObject = new THREE.InstancedMesh(fairLogoBackgroundGeometry, fairLogoBackgroundMaterial, modelLogoStandCount);

                object.castShadow = true;
                object.receiveShadow = true;
                modelLogoStand.mesh = object;

                for (let i = fairLoopStart; i < modelLogoStandCount + 10; i++) {
                    const dummy = new THREE.Object3D();

                    dummy.scale.set(
                        modelLogoStand.scale,
                        modelLogoStand.scale,
                        modelLogoStand.scale,
                    );

                    dummy.position.set(
                        i * (size.x + settings.fairLogoSpacing) + size.x / 2 + settings.fairLogoSpacing,
                        0,
                        settings.boothSize * 2 + settings.boothSpacing + 2
                    );

                    dummy.updateMatrix();
                    if (modelLogoStand.mesh) {
                        (modelLogoStand.mesh as InstancedMesh).setMatrixAt(i - fairLoopStart, dummy.matrix);
                        (modelLogoStand.mesh as InstancedMesh).setColorAt(i - fairLoopStart, color);

                    }

                    dummy.position.y = .5;
                    dummy.position.z = settings.boothSize * 2 + settings.boothSpacing + 2 + 0.05;
                    dummy.updateMatrix();
                    fairLogoObject.setMatrixAt(i - fairLoopStart, dummy.matrix);
                    fairLogoObject.setColorAt(i - fairLoopStart, color);
                    dummy.position.z = settings.boothSize * 2 + settings.boothSpacing + 2 + 0.03;
                    dummy.updateMatrix();
                    fairLogoBackgroundObject.setMatrixAt(i - fairLoopStart, dummy.matrix);
                }
                scene.add(modelLogoStand.mesh);
                scene.add(fairLogoObject);
                scene.add(fairLogoBackgroundObject);
            });
        });

    }
    const floorWidth = settings.boothSize * 2 + 1;
    const floorHeight = settings.boothSize * Math.ceil(fairData.length / 2);
    const startX = -2; // floorHeight / 2;
    const additionalWidth = 300;

    const floorGeometry = new THREE.PlaneGeometry(floorWidth, floorHeight + additionalWidth, 1, 1);
    const floorMaterial = new THREE.MeshLambertMaterial({
        color: 0xF4ECE2,
    });

    floorMaterial.side = THREE.FrontSide;
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.name = 'floor-fair';
    floor.rotation.x = -Math.PI / 2;
    floor.rotation.z = Math.PI / 2;
    floor.position.set(
        startX, //floorHeight / 2,
        0,
        floorWidth / 2
    );
    floor.receiveShadow = true;


    const boothPlateGeometry = new THREE.PlaneGeometry(settings.boothSize, settings.boothSize, 1, 1);
    const boothPlateMaterial = new THREE.MeshBasicMaterial({
        color: new THREE.Color(fair.colorHex ?? settings.fairColor),
        opacity: 0,
        transparent: true
    });
    boothPlateMaterial.side = THREE.FrontSide;
    boothPlates = new THREE.InstancedMesh(boothPlateGeometry, boothPlateMaterial, fairData.length);
    boothPlates.name = 'booth-plate';
    console.log('setup ', fairData.length);
    for (let i = 0; i < fairData.length; i++) {
        const dummy = new THREE.Object3D();
        dummy.rotation.x = -Math.PI / 2;
        let x = Math.floor(i / 2);
        let z = i % 2;
        if (z === 1) {
            dummy.position.set(
                x * settings.boothSize + settings.boothSize / 2,
                0.03,
                z * settings.boothSize + settings.boothSize / 2 + settings.boothSpacing,
            );
        } else {
            dummy.position.set(
                x * settings.boothSize + settings.boothSize / 2,
                0.03,
                z * settings.boothSize + settings.boothSize / 2,
            );
        }

        dummy.updateMatrix();


        const dummy2 = new THREE.Object3D();
        dummy2.rotation.x = -Math.PI / 2;
        dummy2.position.set(dummy.position.x, -1, dummy.position.z);
        dummy2.scale.multiplyScalar(1.05);
        dummy2.updateMatrix();

        boothPlates.setMatrixAt(i, dummy.matrix);
    }
    scene.add(boothPlates);

    const iconPlateGeometry = new THREE.PlaneGeometry(.7, .6, 1, 1);
    const iconTextures = ['/gfx/icon-video.png', '/gfx/icon-chat.png', '/gfx/icon-jobs.png'];
    const iconAttributes = ['hasVideo', 'hasChat', 'linkToThaff'];
    const iconData = ['video', 'chat', 'stellen'];
    let iconOffset: number[] = [];

    for (let j = 0; j < iconTextures.length; j++) {
        const iconTexture = textureLoader.load(iconTextures[j], (texture: Texture) => {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(1, 1);
            texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
            texture.minFilter = THREE.NearestFilter;
            texture.magFilter = THREE.NearestFilter;
        });

        const iconPlateMaterial = new THREE.MeshBasicMaterial({
            opacity: 1,
            transparent: true,
            map: iconTexture,
            toneMapped: false,
        });

        iconPlateMaterial.side = THREE.FrontSide;
        iconPlates = new THREE.InstancedMesh(iconPlateGeometry, iconPlateMaterial, fairData.length);
        const standOffset = models.find(element => element.name === 'stand') as unknown as RenderModels;
        iconPlates.name = 'icon-plate';
        iconPlates.userData = {target: iconData[j]};
        for (let i = 0; i < fairData.length; i++) {
            const dummy = new THREE.Object3D();
            dummy.rotation.y = -Math.PI / 2;
            let x = Math.floor(i / 2);
            let z = i % 2;
            let y = -2;

            if (typeof iconOffset[i] === 'undefined') {
                iconOffset.push(0);
            }

            if (fairData[i][iconAttributes[j]] === true && fair[iconAttributes[j]]) {
                y = 1.9 - .6 * iconOffset[i];
                iconOffset[i]++;
            }

            if (z === 1) {
                dummy.position.set(
                    x * settings.boothSize + ((standOffset.offsetRight.x / 100) * settings.boothSize) - .025,
                    y,
                    z * settings.boothSize + ((standOffset.offsetRight.z / 100) * settings.boothSize) + settings.boothSpacing,
                );
            } else {
                dummy.position.set(
                    x * settings.boothSize + ((standOffset.offsetLeft.x / 100) * settings.boothSize) - .025,
                    y,
                    z * settings.boothSize + ((standOffset.offsetLeft.z / 100) * settings.boothSize),
                );
            }

            dummy.updateMatrix();
            iconPlates.setMatrixAt(i, dummy.matrix);
        }
        scene.add(iconPlates);
    }

    const groundGeometry = new THREE.PlaneGeometry(floorHeight + 1000, floorHeight + 1000, 1, 1);
    const groundTextureBump = textureLoader.load('/gfx/floor_bump.png', (texture: Texture) => {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(1000, 1000);
    });

    const groundMaterial = new THREE.MeshPhongMaterial({
        color: 0xCEC1B3,
        specular: 0xFFFFFF,
        shininess: 30,
        // map: groundTexture,
        bumpMap: groundTextureBump,
        bumpScale: 0.02,
    });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.name = 'ground';
    ground.rotation.x = -Math.PI / 2;
    ground.position.set(
        floorHeight / 2,
        -0.1,
        0
    );
    ground.receiveShadow = false;

    let pathGeometry = new THREE.BoxGeometry(floorHeight + additionalWidth, .03, settings.boothSpacing);
    let pathMaterial = new THREE.MeshLambertMaterial({
        color: 0xE5D8C9,
    });
    pathMaterial.side = THREE.DoubleSide;
    let path = new THREE.Mesh(pathGeometry, pathMaterial);
    path.name = 'path';
    path.position.x = startX; //floorHeight / 2;
    path.position.y = .015;
    path.position.z = settings.boothSize + settings.boothSpacing / 2;
    path.receiveShadow = false;
    path.castShadow = true;

    let fairLogoRatio = 0;

    const renderFairLogo = (texture: Texture, cb: (fairLogoObject: InstancedMesh, fairLogoWidth: number, fairLogoCount: number, i: number) => void) => {
        fairLogoRatio = texture.image.width / texture.image.height;
        texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
        texture.magFilter = THREE.NearestFilter;
        // texture.minFilter = THREE.NearestFilter;

        const fairLogoMaterial = new THREE.MeshBasicMaterial({
            map: texture,
            opacity: .65,
            transparent: true,
            toneMapped: false,
        });

        const fairLogoWidth = fairLogoRatio * settings.fairLogoHeight;
        const fairLogoGeometry = new THREE.PlaneGeometry(fairLogoWidth, settings.fairLogoHeight, 1, 1);
        const fairLogo = new THREE.Mesh(fairLogoGeometry, fairLogoMaterial);

        fairLogo.castShadow = false;
        fairLogo.receiveShadow = false;

        fairLogo.position.set(
            fairLogoWidth / 2,
            .051,
            settings.boothSize + settings.boothSpacing / 2
        );

        const fairLogoCount = Math.floor(floorHeight / (fairLogoWidth + settings.fairLogoSpacing)) + 10;
        let fairLogoObject = new THREE.InstancedMesh(fairLogoGeometry, fairLogoMaterial, fairLogoCount);

        for (let i = fairLoopStart; i < fairLogoCount; i++) {
            cb(fairLogoObject, fairLogoWidth, fairLogoCount, i);
        }
        scene.add(fairLogoObject);
    };

    if (fair.imageWall) {
        textureLoader.load(fair.imageWall, (texture: Texture) => {
            const cb = (fairLogoObject: InstancedMesh, fairLogoWidth: number, fairLogoCount: number, i: number) => {
                const dummy = new THREE.Object3D();

                dummy.rotation.x = 0;
                dummy.position.set(
                    i * (fairLogoWidth + settings.fairLogoSpacing) + fairLogoWidth / 2 + settings.fairLogoSpacing,
                    1,
                    -1.99
                );
                dummy.updateMatrix();
                fairLogoObject.setMatrixAt(i - fairLoopStart, dummy.matrix);
            };
            renderFairLogo(texture, cb);
        });
    }


    if (fair.imageFloor) {
        textureLoader.load(fair.imageFloor, (texture: Texture) => {
            const cb = (fairLogoObject: InstancedMesh, fairLogoWidth: number, fairLogoCount: number, i: number) => {
                const dummy = new THREE.Object3D();

                dummy.rotation.x = -Math.PI / 2;
                dummy.scale.set(1, 1, 1,);

                dummy.position.set(
                    i * (fairLogoWidth + settings.fairLogoSpacing) + fairLogoWidth / 2 + settings.fairLogoSpacing,
                    .051,
                    settings.boothSize + settings.boothSpacing / 2
                );

                dummy.updateMatrix();
                fairLogoObject.setMatrixAt(i - fairLoopStart, dummy.matrix);
            };
            renderFairLogo(texture, cb);
        });
    }


    const companyLogoGeometry = new THREE.PlaneGeometry(2.1, 2.1, 1, 1);
    let companyLogoObject = new THREE.InstancedMesh(companyLogoGeometry, companyLogoMaterial, fairData.length);
    companyLogoObject.name = 'companyLogoObject';
    const logoOffset = models.find(element => element.name === 'screen') as unknown as RenderModels;

    for (let i = 0; i < fairData.length; i++) {
        const dummy = new THREE.Object3D();

        dummy.rotation.y = -Math.PI / 2;
        dummy.scale.set(1, 1, 1,);

        let x = Math.floor(i / 2);
        let z = i % 2;

        if (z === 1) {
            dummy.position.set(
                x * settings.boothSize + ((logoOffset.offsetRight.x / 100) * settings.boothSize) - .019,
                1.3,
                z * settings.boothSize + ((logoOffset.offsetRight.z / 100) * settings.boothSize) + settings.boothSpacing,
            );
        } else {
            dummy.position.set(
                x * settings.boothSize + ((logoOffset.offsetLeft.x / 100) * settings.boothSize) - .019,
                1.3,
                z * settings.boothSize + ((logoOffset.offsetRight.z / 100) * settings.boothSize),
            );
        }


        dummy.updateMatrix();
        companyLogoObject.setMatrixAt(i, dummy.matrix);
    }
    scene.add(companyLogoObject);


    const wallGeometry = new THREE.PlaneGeometry(floorHeight + additionalWidth, 50, 1, 1);
    const wallMaterial = new THREE.MeshBasicMaterial({
        color: new THREE.Color(fair.colorHex ?? settings.fairColor),
        toneMapped: false
    });
    wallMaterial.side = THREE.FrontSide;
    const wall = new THREE.Mesh(wallGeometry, wallMaterial);
    wall.position.set(
        startX, //floorHeight / 2,
        5,
        -2
    );
    wall.receiveShadow = false;
    wall.castShadow = false;

    const fenceGeometry = new THREE.BoxGeometry(floorHeight + additionalWidth, 1, .05);
    // const fenceMaterial = new THREE.MeshPhysicalMaterial({
    //     color: 0xAFCAD7,
    //     transmission: .6,
    //     transparent: false,
    //     opacity: 1,
    //     metalness: 0,
    //     roughness: 0,
    //     thickness: 0.5,
    //     specularIntensity: 1,
    //     specularColor: 0xffffff,
    //     depthWrite: false,
    //     side: THREE.FrontSide
    // });

    const fenceMaterial = new THREE.MeshLambertMaterial({
        color: 0xAFCAD7,
        transparent: true,
        opacity: .3,
        reflectivity: 1,
        side: THREE.FrontSide
    });

    const fence = new THREE.Mesh(fenceGeometry, fenceMaterial);
    fence.position.set(
        startX, //floorHeight / 2,
        .5,
        -.025
    );
    fence.receiveShadow = false;
    fence.castShadow = false;

    const fence2 = new THREE.Mesh(fenceGeometry, fenceMaterial);
    fence2.position.set(
        startX, // floorHeight / 2,
        .5,
        settings.boothSize * 2 + settings.boothSpacing
    );
    fence2.receiveShadow = true;
    fence2.castShadow = true;

    if (isReloading === false) {
        const hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 7);

        const light = new THREE.DirectionalLight(0xffffff, 4);
        const d = 10;
        light.castShadow = true;
        light.shadow.bias = 0.02;
        light.shadow.mapSize.width = light.shadow.mapSize.height = 2048;
        light.shadow.camera.top = light.shadow.camera.right = 100;
        light.shadow.camera.bottom = light.shadow.camera.left = -100;
        light.position.set(-150, 200, -0);

        scene.add(hemiLight);
        scene.add(light);

        isReloading = false;
    }

    gltfLoader.load('/models/welcome.glb', (gltf: any) => {
        const model = gltf.scene;

        model.rotation.y = -Math.PI / 2;
        model.position.set(
            -(settings.boothSize / 4),
            0,
            settings.boothSize + settings.boothSpacing / 2,
        );

        model.traverse((child: Mesh<BufferGeometry, Material>) => {
            if (child.isMesh) {
                child.castShadow = true;
                // child.receiveShadow = true;
            }
        });

        model.castShadow = true;
        model.receiveShadow = true;
        scene.add(model);
    });

    scene.add(wall);
    scene.add(floor);
    scene.add(ground);
    scene.add(path);

    scene.add(fence);
    scene.add(fence2);

    init(fair, fairData);

    placePlants(fairData);
};

// function onMouseWheel(event: MouseEvent) {
//     event.preventDefault();
//     camera.position.x += event.deltaX;
// }

function onWindowResize() {
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.updateProjectionMatrix();
}

function animate() {
    // stats.begin();
    requestAnimationFrame(animate);
    if (isDev()) {
        stats.update();
    }
    controls.update();

    if (settings.showDrawCalls === true) {
        //@ts-ignore
        document.body.querySelector('#test-div').innerHTML = 'Draw:' + renderer.info.render.calls + ' <br/>Poly: ' + renderer.info.render.triangles + '<br/>Textures: ' + renderer.info.memory.textures + '<br/>Geometries: ' + renderer.info.memory.geometries;
        //@ts-ignore
        document.body.querySelector('#test-div-2').innerHTML = 'Zoom:' + camera.zoom + ' <br/>AzimuthalAngle : ' + controls.getAzimuthalAngle() + '<br/>PolarAngle: ' + controls.getPolarAngle();
    }

    rayCast();
    render();
    // stats.end();
}

const render = () => {
    const delta = clock.getDelta();
    if (mixer) {
        mixer.update(delta);
    }

    renderer.render(scene, camera);
};

function onMouseMove(event: MouseEvent) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
}

const getCurrentIntersection = (): Intersection | null => {
    const intersections = raycaster.intersectObjects(scene.children, false);
    const max = intersections.length;
    for (let i = 0; i < max; i++) {
        const intersection = intersections[i];
        const instanceId = intersection.instanceId ?? null as number | null;
        if (instanceId !== null && intersection.object.name && allowedFairProfileObjectNames.includes(intersection.object.name)) {
            return intersection;
        }
    }

    return null;
};

function rayCast() {
    raycaster.setFromCamera(mouse, camera);
    if (boothPlates && hoverProfileMesh) {
        const intersection = raycaster.intersectObject(boothPlates);

        let instanceId: number | null = null;
        if (intersection.length > 0) {
            instanceId = (intersection[0].instanceId ?? null) as number | null;

            if (instanceId !== null && instanceId !== selectedFairProfileIndex) {
                selectFairProfileByIndex(instanceId, hoverProfileMesh);
                hoverFairProfileIndex = instanceId;
            }
        }

        if (hoverFairProfileIndex !== null && (hoverFairProfileIndex !== instanceId)) {
            // move it back...
            unSelectFairProfileByIndex(hoverFairProfileIndex, hoverProfileMesh);
            hoverFairProfileIndex = null;
        }

        const mouseHoverIntersection = getCurrentIntersection();
        if (mouseHoverIntersection) {
            document.body.style.cursor = 'pointer';
        } else {
            document.body.style.removeProperty('cursor');
        }
    }
}

function getRandomFloat(min: number, max: number, decimals: number) {
    const str = (Math.random() * (max - min) + min).toFixed(decimals);
    return parseFloat(str);
}

/**
 * updates a scene after fair or fairData change
 *
 * @param fair
 * @param fairData
 * @param options
 */
export const updateScene = (fair: Fair, fairData: FairProfile[], options: CustomOptions) => {
    isReloading = true;
    localFairs = fairData;
    if(controls.target.x > (Math.ceil(fairData.length / 2) * settings.boothSize)){
        controls.target.set(4, 0, 4);
        camera.position.set(-16, 20, 24);
        camera.updateProjectionMatrix();
    }


    for (let model of models) {
        model.geometries = [];
        model.meshes = [];
        model.materials = [];
    }

    const remainingObjectNames = [
        'selected-profile-mesh',
        'hover-profile-mesh'
    ];

    const removeEverything = () => {

        // const meshes: Array<Mesh<BufferGeometry, Material> | InstancedMesh<BufferGeometry, Material> | Sprite> = [];
        const meshes: Object3D[] = [];

        scene.traverse((object: Object3D) => {
            if ((object instanceof Mesh || object instanceof Sprite || object instanceof Text) && !remainingObjectNames.includes(object.name)) {
                meshes.push(object);
            }
        });

        for (let i = 0; i < meshes.length; i++) {
            const mesh = meshes[i];
            removeObject(mesh);
            scene.remove(mesh);
        }

    };
    removeEverything();

    renderer.renderLists.dispose();
    setupScene(fair, fairData, options);
};


// https://discourse.threejs.org/t/how-to-limit-pan-in-orbitcontrols-for-orthographiccamera/9061/7
export function createLimitPan({camera, controls}: { camera: Camera, controls: OrbitControls }, cb: Function) {
    const v = new THREE.Vector3();
    return ({
                maxX = Infinity,
                minX = -Infinity,
                maxZ = Infinity,
                minZ = -Infinity
            }) => {
        const minPan = new THREE.Vector3(minX, -Infinity, minZ);
        const maxPan = new THREE.Vector3(maxX, Infinity, maxZ);
        v.copy(controls.target);
        controls.target.clamp(minPan, maxPan);

        // cb(v.x, maxX);
        v.sub(controls.target);
        camera.position.sub(v);
    };
}

const removeObject = (node: Object3D) => {

    //parentObject.traverse(function (node) {
    if (node instanceof Mesh) {
        if (node.geometry) {
            node.geometry.dispose();
        }

        if (node.material) {
            if (node.material.map) {
                node.material.map.dispose();
            }
            if (node.material.lightMap) {
                node.material.lightMap.dispose();
            }
            if (node.material.bumpMap) {
                node.material.bumpMap.dispose();
            }
            if (node.material.normalMap) {
                node.material.normalMap.dispose();
            }
            if (node.material.specularMap) {
                node.material.specularMap.dispose();
            }
            if (node.material.envMap) {
                node.material.envMap.dispose();
            }

            node.material.dispose();   // disposes any programs associated with the material
        }
    }
    //});
};

export const updateSelectedFairProfileIndex = (index: number | null) => {
    if (clipAction) {
        clipAction.enabled = false;
        clipAction.stop();
    }
    if (!selectedProfileMesh) {
        throw new Error('selectedProfileMesh is not initialized yet');
    }

    if (selectedFairProfileIndex !== null && index === null) {
        unSelectFairProfileByIndex(selectedFairProfileIndex, selectedProfileMesh);
    }

    selectedFairProfileIndex = index;
    if (index !== null) {
        selectFairProfileByIndex(index, selectedProfileMesh);

        playSelectedClipAction();
    }
};

const selectFairProfileByIndex = (index: number, mesh: Mesh) => {
    if (boothPlates) {
        const dummyMatrix = new Matrix4();
        const dummy = new Object3D();
        boothPlates.getMatrixAt(index, dummyMatrix);
        dummyMatrix.decompose(dummy.position, dummy.quaternion, dummy.scale);
        dummy.position.y = 0.05;
        dummy.updateMatrix();
        mesh.position.set(dummy.position.x, dummy.position.y, dummy.position.z);
        mesh.matrixWorldNeedsUpdate = true;
        mesh.updateMatrix();
    }
};

const unSelectFairProfileByIndex = (index: number, mesh: Mesh) => {
    if (boothPlates) {
        const dummyMatrix = new Matrix4();
        const dummy = new Object3D();
        boothPlates.getMatrixAt(index, dummyMatrix);
        dummyMatrix.decompose(dummy.position, dummy.quaternion, dummy.scale);
        dummy.position.y = -2;
        dummy.updateMatrix();
        mesh.position.set(dummy.position.x, dummy.position.y, dummy.position.z);
        mesh.updateMatrix();
    }
};

const freeCam = () => {
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.minAzimuthAngle = Infinity;
    controls.maxAzimuthAngle = Infinity;
    controls.minZoom = 0;
    controls.maxZoom = Infinity;
    controls.update();
};

if(isDev()){
    w.freeCam = freeCam;
}

interface Position {
    x: number,
    y: number,
    z: number
}

interface PlantPositions {
    [key: number]: {
        position: Position | null,
        index: number,
        arrayIndex: number | null,
        name?: string | null,
        plantId?: number | null,

    };
}

let placedPlants: PlantPositions = {};
w.placedPlants = placedPlants;

const randomPlantPositions: Position[] = [
    {x: 1, y: 0, z: 1.2},
    {x: .8, y: 0, z: 1.4},
    {x: -2.5, y: 0, z: -2},
];

const randomPlantPositionsRight: Position[] = [
    {x: 1, y: 0, z: 1.2},
    {x: .8, y: 0, z: 1.4},
    {x: 1, y: 0, z: 1},
];

// const a: any = {};
// const c: any = [];
/**
 * get a plant for a specific profile
 *
 * @param name
 * @param arrPlants
 * @param i
 * @param profileId
 * @param allPlants
 */
const getPlantForProfile = (
    name: string | null,
    arrPlants: Array<{ plant: PlantModels | null, index: number }>,
    i: number,
    profileId: number,
): { meshes: InstancedMesh[], dummy: Object3D | null, arrayIndex: number | null } => {

    let x = Math.floor(i / 2);
    let z = i % 2;

    let meshes: InstancedMesh[] = [];
    let position: Position | null = null;
    let arrayIndex: number | null;
    let plantId: number | null = null;
    let index: number | null = null;

    // check if the data for this fairProfile is already cached?
    const profileData = placedPlants[profileId] ?? null;

    if (profileData && profileData.index !== null) {
        // grab the correct index in the array for this profile
        index = profileData.index;
        arrayIndex = arrPlants.findIndex(el => el && el.index === index);

        const p = arrPlants[arrayIndex] ?? null;
        if (arrayIndex !== -1 && p && p.plant && p.plant.meshes) {
            // grab the correct plant
            meshes = p.plant.meshes;
            plantId = p.plant.id;
            index = p.index;
        } else {
            meshes = [];
        }
        // grab the correnct position
        position = profileData.position;

    } else {
        // in case it's not cached... grab a random plant from the array
        arrayIndex = randomIntFromInterval(0, arrPlants.length - 1);
        const p = arrPlants[arrayIndex] ?? null;
        if (!p || !p.plant || !p.plant.meshes) {
            // no plant for this profile
            placedPlants[profileId] = {
                index: p?.index ?? -1,
                arrayIndex: arrayIndex,
                plantId,
                position: null,
                name,
            };
        } else {
            // there is indeed a plant for this profile... create a position for it
            meshes = p.plant.meshes;
            plantId = p.plant.id;
            index = p.index;
            // console.log('name', name, 'plantId', plantId, 'arrayIndex', arrayIndex, 'index', p.index);
            let randomPosition = randomPlantPositions[randomIntFromInterval(0, randomPlantPositions.length - 1)] as Position;
            if (z === 1) {
                randomPosition = randomPlantPositionsRight[randomIntFromInterval(0, randomPlantPositionsRight.length - 1)] as Position;
            }

            position = {
                x: randomPosition.x,
                y: 0,
                z: randomPosition.z,
            };
        }
    }

    // no plant...
    if (position === null || meshes.length === 0) {
        return {
            meshes: [],
            dummy: null,
            arrayIndex,
        };
    }

    // if (typeof a[profileId] !== 'undefined') {
    //     const b: any = a[profileId];
    //     //@ts-ignore
    //     if (b.plantId !== plantId) {
    //         console.log('plant changed', profileId, name, position, plantId, 'index', index, 'arrayIndex', arrayIndex);
    //         console.log('old', b.name, b.position, b.plantId, 'index', b.index, 'arrayIndex', b.arrayIndex);
    //     }
    //
    //     if (b.index !== index) {
    //         console.log('randomEntry changed', profileId, name, 'plantId', plantId, 'index', index, 'arrayIndex', arrayIndex);
    //         console.log('old', b.name, b.position, 'plantId', b.plantId, 'index', b.index, 'arrayIndex', b.arrayIndex);
    //         console.log(a);
    //         debugger;
    //     }
    // }
    // a[profileId] = {plantId, position, name, index, arrayIndex};

    const dummy = new THREE.Object3D();
    dummy.scale.set(
        .4,
        .4,
        .4,
    );

    const offsetX = x * settings.boothSize + settings.boothSize / 2;
    const offsetZ = z * settings.boothSize + settings.boothSize / 2;

    if (z === 1) {
        dummy.position.set(
            position.x + offsetX,
            position.y,
            position.z + offsetZ + settings.boothSpacing,
        );
    } else {
        dummy.position.set(
            position.x + offsetX,
            position.y,
            position.z + offsetZ,
        );
    }

    dummy.updateMatrix();
    placedPlants[profileId] = {
        index: index as number,
        arrayIndex: arrayIndex,
        plantId,
        position,
        name,
    };

    return {dummy, meshes, arrayIndex};
};


let maxNrOfPlants: number = 0;
/**
 * Place all plants for the currently filtered profiles
 *
 * @param fairData
 */
const placePlants = async (fairData: FairProfile[]) => {

    const plants: PlantModels[] = [];

    for (let i = 0; i < 5; i++) {
        plants.push({
            id: i,
            filename: `plant-0${i + 1}.glb`,
            geometries: [],
            materials: [],
            meshes: [],
            scale: 1,
        });
    }

    //TODO: improve this... it will "break" when a fairProfile is added during runtime.
    // Currently this is unlikely to happen ¯\_(ツ)_/¯ to fix that later
    // if thaff adds a profile while the fair is currently running
    // all plant positions will be randomized again -> not critical
    if (maxNrOfPlants < fairData.length) {
        placedPlants = {};
        maxNrOfPlants = fairData.length;
    }

    let countPerPlant = Math.floor(maxNrOfPlants / 2 / plants.length);
    let arrPlants: Array<{ plant: PlantModels | null, index: number }> = [];

    let k = 0;
    for (let i = 0; i < plants.length; i++) {
        for (let j = 0; j < countPerPlant; j++) {
            arrPlants.push({plant: plants[i], index: k});
            k++;
        }
    }

    // fill the missing spaces with empty plants
    for (let i = arrPlants.length; i < maxNrOfPlants; i++) {
        arrPlants.push({index: i, plant: null});
    }

    let meshId = 0;
    for (let i = 0; i < plants.length; i++) {

        const children = await getChildrenForPlant(plants[i]);
        children.forEach((child) => {
            if (child instanceof Mesh) {
                let geometry = child.geometry;
                geometry.computeVertexNormals();
                geometry.castShadow = true;
                plants[i].geometries.push(geometry);
                plants[i].materials.push(child.material);
                // console.log(geometry)
                let object = new THREE.InstancedMesh(geometry, child.material, countPerPlant);
                object.userData = {
                    id: meshId,
                    plantId: i,
                };
                object.castShadow = true;
                plants[i].meshes.push(object);
                meshId++;
            }
        });
    }

    let instanceIndexes: { [key: number]: number } = {};
    for (let i = 0; i < fairData.length; i++) {
        const profileId = fairData[i].id;
        if (profileId !== null) {

            const {meshes, dummy, arrayIndex} = getPlantForProfile(
                fairData[i].title,
                arrPlants,
                i,
                profileId
            );
            if (dummy !== null && meshes.length !== 0) {
                meshes.forEach(mesh => {
                    const meshId = mesh.userData?.id ?? 0;
                    let currentIndex = instanceIndexes[meshId] ?? 0;
                    mesh.setMatrixAt(currentIndex, dummy.matrix);
                    mesh.setColorAt(currentIndex, color);
                    currentIndex++;
                    instanceIndexes[meshId] = currentIndex;
                });
            }

            if (arrayIndex !== null) {
                arrPlants.splice(arrayIndex, 1);
            }
        }
    }

    plants.forEach(plant => {
        plant.meshes.forEach(mesh => scene.add(mesh));
    });
};

const childrenByPlant: { [key: number]: Mesh[] } = {};
/**
 * get children for a plant, cache the result
 *
 * @param plant
 */
const getChildrenForPlant = async (plant: PlantModels): Promise<Object3D[]> => {
    return new Promise((resolve) => {
        const children = childrenByPlant[plant.id] ?? null;
        if (children !== null) {
            resolve(children);
        }

        gltfLoader.loadAsync('/models/' + plant.filename)
            .then(gltf => {
                let c: Mesh[] = [];
                gltf.scene.traverse((child: Object3D) => {
                  if (child instanceof Mesh) {
                      c.push(child);
                  }
                });

                resolve(c);
            });
    });
};

const randomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

// just a few information ¯\_(ツ)_/¯
if (isDev()) {
    console.log('=============================================');
    console.log(' ');
    console.log(' ');
    console.log(' Three JS loaded... global available objects ');
    console.log(' - window.camera ');
    console.log(' - window.controls ');
    console.log(' - window.scene ');
    console.log(' - window.clipAction ');
    console.log(' - window.freeCam(); // unlimits the camera ');
    console.log(' ');
    console.log(' ');
    console.log('=============================================');
}
