<template>
    <div
        ref="boothContainer"
        class="booth nobooth">
        <div
            class="booth-content">
            <div class="screen">
                <div class="image">
                    <img
                        :src="fair.logoUrl"
                        alt="Unternehmenslogo">
                </div>
                <div class="shadow" />
            </div>
            <div class="display">
                <div class="shadow" />
                <ul />
            </div>
            <div
                class="person roomba">
                <img
                    src="/gfx/person-nobooth-roomba.svg"
                    alt="Ansprechpartner">
            </div>
            <div
                class="person mopping">
                <img
                    src="/gfx/person-nobooth-mopping.svg"
                    alt="Ansprechpartner">
            </div>
            <div
                class="person driving">
                <img
                    src="/gfx/person-nobooth-driving.svg"
                    alt="Ansprechpartner">
            </div>
            <div class="table">
                <img
                    src="/gfx/table.svg"
                    alt="Tisch"
                    class="gfx-table">
            </div>
        </div>
    </div>
    <div
        ref="boothContainer"
        class="booth nobooth"
        style="transform: translateZ(1px) translateY(-50px);">
        <div
            class="booth-content">
            <div class="screen">
                <div class="image">
                    <h2>Zu ihrer Suche konnten keine passenden Treffer gefunden werden.</h2>
                </div>
                <div class="shadow" />
            </div>
            <div class="display">
                <div class="shadow" />
                <ul />
            </div>
            <div
                class="person cleaning">
                <img
                    src="/gfx/person-nobooth-cleaning.svg"
                    alt="Ansprechpartner">
            </div>
            <div class="table">
                <img
                    src="/gfx/table.svg"
                    alt="Tisch"
                    class="gfx-table">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import FairComposition from "@/compositions/FairComposition";
import Fair from "@/models/Fair";
import FairProfile from "@/models/FairProfile";
import {Ref} from "@vue/reactivity";
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
    name: "Booth",
    emits: [
        'mounted'
    ],
    setup(props, {emit}) {
        const {profileSlug, toggleProfile} = FairComposition()


        const boothContainer: Ref<HTMLElement | null> = ref(null)

        const mounted = onMounted(() => {
            if (boothContainer.value) {
                emit('mounted', boothContainer.value?.getClientRects()[0].height);
            }
        })

        return {
            profileSlug,
            toggleProfile,

            boothContainer,
            mounted
        }
    },
    computed: {
        fair(): Fair {
            return this.$store.getters['fairs/current'];
        }
    }
})
</script>

<style scoped>

</style>
