
import CurrentMoment from "@/compositions/CurrentMoment";
import Fair from "@/models/Fair";
import moment from "moment";
import {computed, defineComponent, onMounted, onUnmounted, reactive, watch} from 'vue'
import {useStore} from "vuex";
import {IActiveChat, IActiveVideo} from "@/types/socket";
import FairComposition from "@/compositions/FairComposition";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
    name: "FairStatusBar",
    setup() {
        const store = useStore();
        const router = useRouter();
        const {params: routeParams} = useRoute();
        const {toggleProfile, currentProfile} = FairComposition()
        const state = reactive({
            showMessages: false,
            showCurrentPrograms: true
        })

        // hide message after 5s
        window.setTimeout(() => {
            state.showCurrentPrograms = false;
        }, 5000)

        const activeCompanyProfile = computed(() => {
            console.log('TEST: tab', router.currentRoute.value.params.tab);
            return currentProfile.value && router.currentRoute.value.params.tab === 'chat' ? currentProfile.value : false;
        })

        const activeChats = computed<Array<IActiveChat | IActiveVideo>>(() => {
            const chats = [...store.getters['socket/activeChats'], ...videosToShow.value];

            // nur chats zurückgeben, welche ungelesene nachrichten haben und ich nicht offen habe...
            return chats.filter(chat => {
                if (!getUnreadCount.value(chat.companyId)) {
                    return false;
                }
                if (activeCompanyProfile.value && activeCompanyProfile.value.uid === chat.companyId) {
                    return false;
                }

                return true;
            });
        })

        const videosToShow = computed<IActiveVideo[]>(() => {
            return store.getters['socket/activeVideos'].filter((item: IActiveVideo) => !item.clientConnected)
        })

        const getAllUnreadcount = computed(() => {
            let unreadCount = 0;
            for (const chat of activeChats.value) {
                if ("messages" in chat && chat.messages) {
                    unreadCount += chat.messages.length - chat.readIndex;
                }
                if ("clientConnected" in chat && !chat.clientConnected) {
                    unreadCount += 1
                }
            }

            return unreadCount;
        });

        const getUnreadCount = computed(() => {
            return (companyId: string) => {
                const chat = store.getters['socket/activeChatByCompany'](companyId)
                if (chat) {
                    return chat.messages.length - chat.readIndex;
                }
                // sollte ein video an der stelle sein
                return 1;
            }
        })

        const openProfile = (chat: IActiveChat | IActiveVideo) => {
            if ("messages" in chat) {
                const profile = store.getters['fair-profiles/oneByUid'](chat.companyId)
                if (profile) {
                    toggleProfile(profile, 'chat')
                }
            } else {
                const currentRouteFair = routeParams.slug;
                if (currentRouteFair) {
                    const route = router.resolve({name: 'fairVideoChat', params: {slug: currentRouteFair, roomUrl: chat.roomUrl}})
                    window.open(route.href);
                }
            }
        }

        const getChatContact = computed(() => {
            return (chat: IActiveChat | IActiveVideo) => {
                if (!chat.chatContactId) {
                    return null;
                }

                return store.getters['chat-contact/get'](chat.chatContactId)
            }
        })

        const companyTitle = computed(() => {
            return (chat: IActiveChat | IActiveVideo) => {
                return store.getters['fair-profiles/oneByUid'](chat.companyId)?.title;
            }
        })

        const {
            onMounted,
            onUnmounted,
            currentMoment
        } = CurrentMoment(10000);

        const currentFair = store.getters['fairs/current'] as Fair | null;

        const currentActivePrograms = computed<number>(() => {
            if (!currentFair) {
                return 0;
            }
            return currentFair.programs.filter(el => el.startDate && el.endDate && el.startDate.isSameOrBefore(currentMoment.value) && el.endDate.isSameOrAfter(currentMoment.value)).length;
        })
        let programTimeout: number | null = null;
        watch(currentActivePrograms, (newValue, oldValue) => {
            if(newValue > oldValue){
                if(programTimeout !== null){
                    window.clearTimeout(programTimeout);
                    programTimeout = null;
                }
                state.showCurrentPrograms = true;
                programTimeout = setTimeout(() => {
                    state.showCurrentPrograms = false;
                }, 5000)
            }
        })

        const openPrograms = () => {
            if(currentFair && currentFair.slug){
                router.push({name: 'fairInfo', params: {slug: currentFair.slug, tab: 'program'}})
            }
        }

        return {
            state,
            getChatContact,
            companyTitle,
            activeChats,
            getUnreadCount,
            getAllUnreadcount,
            openProfile,
            currentActivePrograms,
            openPrograms,

            // current moment
            onMounted,
            onUnmounted,
            currentMoment
        }
    }
});
