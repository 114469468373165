
import FairComposition from "@/compositions/FairComposition";
import Fair from "@/models/Fair";
import FairProfile from "@/models/FairProfile";
import {AccessoryData} from "@/interfaces/types";
import {Ref} from "@vue/reactivity";
import {defineComponent, onMounted, PropType, ref} from "vue";

export default defineComponent({
    name: "Booth",
    props: {
        position: {
            type: Number,
            required: true
        },
        profile: {
            required: true,
            type: FairProfile
        },
        observer: {
            required: true,
            type: IntersectionObserver
        },
        accessoryData: {
            type: Object as PropType<AccessoryData>,
            required: true
        }
    },
    emits: [
        'mounted'
    ],
    setup(props, {emit}) {
        const {profileSlug, toggleProfile} = FairComposition()


        const boothContainer: Ref<HTMLElement | null> = ref(null)

        const mounted = onMounted(() => {
            if (boothContainer.value) {
                const content = boothContainer.value?.querySelector('.booth-content');
                if (content) {
                    props.observer.observe(boothContainer.value);
                    // props.observer.observe(content);
                }

                emit('mounted', boothContainer.value?.getClientRects()[0].height);
            }
        })

        return {
            profileSlug,
            toggleProfile,

            boothContainer,
            mounted
        }
    },
    computed: {
        getColorStyle() {
            if (!this.fair || !this.fair.colorHex) {
                return null;
            }
            const fair = <Fair>this.fair;

            return {background: fair.colorHex};
        },
        fair(): Fair {
            return this.$store.getters['fairs/current'];
        },
    },
})
