<template>
    <main
        ref="loadingContainer"
        :class="{preview: isLive === false}">
        <template v-if="fair">
            <embed
                id="standSvg"
                style="display: none"
                src="/gfx/stand.svg">

            <img
                id="calculate-svg-wall"
                :src="fair.svgWall"
                style="display: none"
                alt="Logo">
            <img
                id="calculate-svg-floor"
                :src="fair.svgFloor"
                style="display: none"
                alt="Logo">
            <img
                id="calculate-svg-stand"
                :src="fair.svgStand"
                style="display: none"
                alt="Logo">

            <div
                v-if="fair.logoUrl"
                id="logo">
                <router-link
                    :to="{name: 'fair', params: {slug: fair.slug}}"
                    title="">
                    <img
                        :src="fair.logoUrl"
                        alt="Logo zur Messe">
                </router-link>
            </div>


            <template v-if="isLive && $route.name !== 'fairVideoChat'">
                <FairStatusBar />
                <ToolBar
                    :filters="filters"
                    :profiles="filteredFairProfiles.filter(el => el.isPlaceHolder === false)"
                    :scroll-value="currentScrollPosition"
                    @set-filter="setFilter"
                    @open-click="toggleLeftBox"
                    @drag-start="isDragging = true"
                    @drag-end="isDragging = false"
                    @scroll="scroll" />
                <router-view v-if="fair && socketInitialized" />
                <ExhibitorsThree
                    v-if="fair && socketInitialized"
                    ref="exhibitorContainer"
                    :fair="fair"
                    :filtered-fair-profiles="filteredFairProfiles"
                    @scroll="updateScrollBar" />

                <!--                <Exhibitors-->
                <!--                    v-if="fair && socketInitialized"-->
                <!--                    ref="exhibitorContainer"-->
                <!--                    :fair="fair"-->
                <!--                    :filtered-fair-profiles="filteredFairProfiles"-->
                <!--                    @booth-mounted="boothMounted" />-->

                <div
                    v-if="fair.hasFeedbackForm"
                    class="modal"
                    :class="{open: showFeedbackForm}">
                    <div class="modal-content">
                        <button
                            class="modal-close btn btn-icon primary icon-close"
                            @click="showFeedbackForm = false" />
                        <div class="modal-header">
                            {{ fair.feedbackHeadline }}
                        </div>
                        <h2>Wie finden Sie die Messe? Geben Sie uns Feedback!</h2>
                        <div v-html="fair.feedbackText" />

                        <SLink
                            v-if="fair.feedbackLink"
                            class="btn primary"
                            :link="fair.feedbackLink" />
                    </div>
                    <div class="modal-bg" />
                </div>
            </template>
            <template v-else-if="isLive && $route.name === 'fairVideoChat'">
                <div
                    class="video-bar"
                    :style="{'background-color': fair.colorHex}">
                    <button
                        class="btn primary"
                        @click="endActiveVideo">
                        Videoanruf beenden
                    </button>
                </div>
                <router-view class="video-wrap" />
            </template>
            <template v-else>
                <img
                    :src="fair.backgroundImageUrl"
                    alt="Hintergrundbild">
                <FairInfo />

                <div
                    v-if="fair.hasFeedbackForm && isStarted"
                    class="modal"
                    :class="{open: showFeedbackForm}">
                    <div class="modal-content">
                        <button
                            class="modal-close btn btn-icon primary icon-close"
                            @click="showFeedbackForm = false" />
                        <div class="modal-header">
                            {{ fair.feedbackHeadline }}
                        </div>
                        <h2>Wie finden Sie die Messe? Geben Sie uns Feedback!</h2>
                        <div v-html="fair.feedbackText" />

                        <SLink
                            v-if="fair.feedbackLink"
                            class="btn primary"
                            :link="fair.feedbackLink" />
                    </div>
                    <div class="modal-bg" />
                </div>

                <template v-if="$route.name === 'fairProfilePreview'">
                    <router-view />
                </template>
            </template>
        </template>
    </main>
</template>

<script lang="ts">
import {joinRooms, sendVideoEnd} from '@/api/socket/Client';
import Booth from '@/components/base/Booth.vue';
import Exhibitors from '@/components/base/Exhibitors.vue';
import SLink from '@/components/base/SLink.vue';
import StatusBar from '@/components/base/StatusBar.vue';
import ToolBar from '@/components/base/ToolBar.vue';
import CurrentMoment from '@/compositions/CurrentMoment';
import FairComposition, {EFeedBackSource} from '@/compositions/FairComposition';
import FetchDataComposition, {Action} from '@/compositions/FetchDataComposition';
import LoadingComposition from '@/compositions/LoadingComposition';
import Fair from '@/models/Fair';
import FairProfile from '@/models/FairProfile';
import {isMobile as isMobileCheck, showError} from '@/services/Utils';
import {IActiveVideo} from '@/types/socket';
import FairInfo from '@/views/FairInfo.vue';
import FairStatusBar from '@/views/FairStatusBar.vue';
import {ComputedRef, Ref} from '@vue/reactivity';
import {AxiosError} from 'axios';
import moment from 'moment';
import {computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {getUserId} from '@/services/Authmanager';
import ExhibitorsThree from '@/components/base/ExhibitorsThree.vue';


const getSvgTemplate = (
    svg: HTMLImageElement, url: string, width: number | string, height: number | string, a: number | null = null, b: number | null = null): string => {

    let svgWidth = 0;
    let svgHeight = 0;
    if (typeof width === 'string' && (width[0] === '+' || width[0] === '-')) {
        const operator = width[0];
        switch (operator) {
            case '+':
                svgWidth = svg.width + Number.parseInt(width.substr(1, width.length));
                break;
            case '-':
                svgWidth = svg.width - Number.parseInt(width.substr(1, width.length));
                break;
        }
    } else if (typeof width === 'number') {
        svgWidth = width;
    }

    if (typeof height === 'string' && (height[0] === '+' || height[0] === '-')) {
        const operator = height[0];
        switch (operator) {
            case '+':
                svgHeight = svg.height + Number.parseInt(height.substr(1, height.length));
                break;
            case '-':
                svgHeight = svg.height - Number.parseInt(height.substr(1, height.length));
                break;
        }
    } else if (typeof height === 'number') {
        svgHeight = height;
    }

    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${svgWidth}" height="${svgHeight}"><image width="${a ?? svg.width}" height="${b ?? svg.height}" xlink:href="${url}" /></svg>`;
};

const isMobile = isMobileCheck();
export default defineComponent({
    name: 'Fair',
    components: {
        FairStatusBar,
        FairInfo,
        Booth,
        StatusBar,
        ToolBar,
        Exhibitors,
        SLink,
        ExhibitorsThree
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        let fairSlug: string | null = null;
        let actions = [
            new Action({
                type: 'job-categories/all',
                once: true,
            })
        ];

        let timer: number | null = null;
        fairSlug = (route.params?.slug as string) ?? null;
        if (fairSlug) {
            actions.push(new Action({
                type: 'fairs/one-by-slug',
                params: {slug: fairSlug},
                once: true,
                cache: {
                    getter: 'fairs/oneBySlug',
                    id: fairSlug
                },
                afterAction(fair: Fair | null) {
                    if (fair && fair.slug && fair.id) {
                        if (fair.feedbackTimeout !== null) {
                            timer = window.setTimeout(() => {
                                if (fair.hasFeedbackForm) {
                                    openFeedbackForm(false, EFeedBackSource.timeout);
                                }
                                timer = null;
                            }, fair.feedbackTimeout * 1000);
                        }
                        if (fair.mouseTimeout !== null) {
                            document.body.addEventListener('mouseleave', mouseLeaveEvent);
                        }

                        store.dispatch('fairs/set-current', fair);
                        if (fair.title) {
                            document.title = fair.title;
                        }
                        if (!isMobile && route.name === 'fairInfo') {
                            const currentTab = route.params?.tab ?? null;
                            if (currentTab === null) {
                                router.push({name: 'fairInfo', params: {slug: fair.slug}});
                            }
                        }


                        nextTick(() => {
                            const svgWall = document.getElementById('calculate-svg-wall') as HTMLImageElement;
                            const svgFloor = document.getElementById('calculate-svg-floor') as HTMLImageElement;
                            const svgStand = document.getElementById('calculate-svg-stand') as HTMLImageElement;
                            const stand = document.getElementById('standSvg') as HTMLEmbedElement;
                            if (svgWall && svgFloor && svgStand && stand) {
                                const svgWallUrl = fair.svgWall ? getSvgTemplate(svgWall, fair.svgWall, '+50', '+75') : '';
                                const svgStandUrl = fair.svgStand ? getSvgTemplate(svgStand, fair.svgStand, 275, 75, 110, 75) : '';
                                const svgFloorUrl = fair.svgFloor ? getSvgTemplate(svgFloor, fair.svgFloor, '+100', 25) : '';
                                const styleTag = document.createElement('style');
                                const basicStand = svgStandUrl ? `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="275" height="75"><image width="275" height="75" xlink:href="${fair.standBackground}" /></svg>` : '';
                                const node = document.createTextNode(`:root {
                                    --logo-url: url('${svgWallUrl}');
                                    --logo-url-path: url('${svgFloorUrl}');
                                    --logo-url-stand-logo: url('${svgStandUrl}');
                                    --logo-url-stand: url('${basicStand}');
                                }`);

                                styleTag.appendChild(node);
                                document.head.appendChild(styleTag);
                            }
                        });


                        const userId = getUserId(fair.id);
                        if (userId === null) {
                            // userId = null -> first time user
                            trackFairEvent('users');
                        }
                        trackFairEvent('page-views');
                    }

                    startLoading();
                    // you can not fetch the identity before you have a fair
                    store.dispatch('user/get-identity')
                         .then(() => {
                             // connect to socket (erst wenn alle daten geladen sind)
                             store.dispatch('user/join-rooms', {token: store.getters['user/jwt']});

                             endLoading();
                         })
                         .catch(err => {
                             showError(err);
                             endLoading();
                         });
                },
                catch(err: AxiosError) {
                    const status: number | null = err.response?.status ?? 0;
                    if (status === 404) {
                        router.push({name: 'not-found'});
                    }
                }
            }));
        }

        const exhibitorContainer: Ref<typeof Exhibitors | null> = ref(null);

        const filteredFairProfiles: ComputedRef<FairProfile[]> = computed(() => {
            const hasChat: boolean = filters.value.hasChat ?? false;
            const hasVideo: boolean = filters.value.hasVideo ?? false;

            const fairCategories: number[] = filters.value.fairCategories ?? [];
            const jobCategories: number[] = filters.value.jobCategories ?? [];

            const profiles = store.getters['fair-profiles/all'].filter((profile: FairProfile) => {
                if (hasChat && !profile.hasChat) {
                    return false;
                }

                if (hasVideo && !profile.hasVideo) {
                    return false;
                }

                if (fairCategories.length) {
                    const inBoth = profile.fairCategoryIds.filter((value: number) => fairCategories.includes(value)) || [];
                    if (inBoth.length === 0) {
                        return;
                    }
                }

                if (jobCategories.length) {
                    const inBoth = profile.jobCategoryIds.filter((value: number) => jobCategories.includes(value)) || [];
                    if (inBoth.length === 0) {
                        return;
                    }
                }

                return true;
            });

            // if (profiles.length % 2) {
            //     profiles.unshift(new FairProfile({isPlaceHolder: true, id: 0}));
            // }

            return profiles;
        });

        const filters: { [key: string]: any } = ref({});
        const setFilter = ({attribute, value}: { attribute: string, value: any }) => {
            if (value !== null) {
                filters.value[attribute] = value;
            } else {
                delete filters.value[attribute];
            }
        };

        const singleBoothHeight: Ref<number | null> = ref(null);
        let first = true;
        const boothMounted = (event: number) => {
            const rounded = Math.round(event);
            if (rounded !== singleBoothHeight.value) {
                singleBoothHeight.value = rounded;

                const height = (Math.floor(filteredFairProfiles.value.length / 2) * rounded);
                if (loadingContainer.value !== null) {
                    loadingContainer.value.style.height = height + 'px';
                }


                if (first) {
                    first = false;
                    // is there a profile slug
                    let height = getHeight.value;
                    let scrollTo = height;
                    if (profileSlug.value) {
                        const index = filteredFairProfiles.value.findIndex((el: FairProfile) => el.slug === profileSlug.value);
                        if (index !== -1) {
                            const row = Math.floor((index + 1) / 2);
                            const rows = Math.floor(filteredFairProfiles.value.length / 2);
                            const percent = (height * (row / rows));
                            scrollTo = Math.round(percent);
                            if (!isMobile) {
                                window.scrollTo({left: 0, top: height - scrollTo, 'behavior': 'smooth'});
                            }
                        }
                    }

                    if (isMobile) {
                        window.setTimeout(() => {
                            window.scrollTo(0, scrollTo);
                        }, 200);
                        first = false;
                    }
                }

            }
        };


        watch(() => filteredFairProfiles.value, () => {
            if (singleBoothHeight.value && loadingContainer.value) {
                const height = (Math.floor(filteredFairProfiles.value.length / 2) * singleBoothHeight.value);
                loadingContainer.value.style.height = height + 'px';
            }
        });

        const getHeight: ComputedRef<number> = computed(() => {
            const height = singleBoothHeight.value ?? 1;

            return (Math.floor(filteredFairProfiles.value.length / 2) * height);
        });

        const loadingContainer: Ref<null | HTMLElement> = ref(null);
        const {
            startLoading,
            endLoading,
            isLoading
        } = LoadingComposition(loadingContainer);

        let firstLoading = true;
        const internalLoadingEnd = () => {
            if (!firstLoading) {
                endLoading();
            }
            firstLoading = false;
        };

        const {mounted, unMounted} = FetchDataComposition({
            actions,
            onLoadingStart: startLoading,
            onLoadingEnd: internalLoadingEnd
        });

        /**
         * Get the fair
         */
        const fair = computed<Fair | null>(() => {
            return fairSlug ? store.getters['fairs/current'] : null;
        });


        const isDragging: Ref<boolean> = ref(false);
        const currentScrollPosition: Ref<number> = ref(0);
        const {toggleProfile, profileSlug, openFeedbackForm, showFeedbackForm, trackFairEvent} = FairComposition();

        const scrollGrid = () => {
            const booths = exhibitorContainer.value;
            if (!booths) {
                return false;
            }

            const container = booths.exhibitorContainer;

            const mainHeight = loadingContainer.value ? loadingContainer.value.offsetHeight : null;
            if (container && mainHeight) {
                const bodyHeight = document.body.offsetHeight;
                let transY = (window.pageYOffset / ((mainHeight) - bodyHeight)) * -100;

                const v = isMobile ? transY : -100 - transY;
                container.style.setProperty('--scroll', v + '%');

                if (!isDragging.value) {
                    const y = transY * -1;
                    currentScrollPosition.value = isMobile ? 100 - y : y;
                }
            }
        };
        const timerStartTime = moment();
        const thisUnmounted = onBeforeUnmount(() => {
            window.removeEventListener('resize', scrollGrid);
            window.removeEventListener('scroll', scrollGrid);
            document.body.removeEventListener('mouseleave', mouseLeaveEvent);
            if (timer) {
                window.clearTimeout(timer);
            }
        });
        const mouseLeaveEvent = () => {
            const end = fair.value?.mouseTimeout ?? null;
            if (end !== null && moment().diff(timerStartTime, 'seconds') > end) {
                if (timer) {
                    window.clearTimeout(timer);
                }
                openFeedbackForm(false, EFeedBackSource.mouseout);
                document.body.removeEventListener('mouseleave', mouseLeaveEvent);
            }
        };

        const thisMounted = onMounted(() => {
            window.addEventListener('resize', scrollGrid);
            window.addEventListener('scroll', scrollGrid);
        });

        const {
            onMounted: momentOnMounted,
            onUnmounted: momentOnUnmounted,
            currentMoment
        } = CurrentMoment(60000);

        const isPreview = route?.query?.preview ?? false;


        const activeVideo = computed<IActiveVideo | null>(() => {
            if (!route?.params.roomUrl) {
                return null;
            }
            return store.getters['socket/activeVideoByRoomUrl'](route?.params.roomUrl);
        });

        const endActiveVideo = () => {
            if (activeVideo.value) {
                sendVideoEnd(activeVideo.value.companyId, activeVideo.value.userId);
                window.close();
                router.push({
                    name: 'fair',
                    params: {slug: route.params.slug}
                });
            }
        };

        const isStarted = computed<boolean>(() => {
            return currentMoment.value && fair.value !== null && (currentMoment.value.isAfter(fair.value.startDate));
        });

        return {
            endActiveVideo,
            startLoading,
            endLoading,
            isLoading,
            loadingContainer,
            fair: fair as unknown as Fair,
            fairSlug,
            trackFairEvent,
            // fetch Data composition
            mounted, unMounted,
            // lifecycle hooks
            thisMounted, thisUnmounted,

            // current Moment
            momentOnMounted, momentOnUnmounted, currentMoment,

            // refs
            exhibitorContainer,
            // data
            isPreview,
            toggleProfile,
            currentScrollPosition,
            isDragging,
            singleBoothHeight,
            getHeight,
            profileSlug,
            // filter
            filters,
            setFilter,
            filteredFairProfiles,
            boothMounted,
            scrollGrid,
            // feebackform
            showFeedbackForm,
            isStarted
        };
    },
    computed: {
        socketInitialized() {
            return this.$store.getters['user/socket-initialized'];
        },
        isLive() {
            if (this.isPreview) {
                return true;
            }

            const c: moment.Moment | null = (this as any).currentMoment;
            const fair: Fair | null = (this as any).fair;
            if (!c || !fair) {
                return false;
            }

            return c.isAfter(fair.startDate) && c.isBefore(fair.publishEndDate);
        }
    },
    methods: {
        updateScrollBar(event: number){
            console.log('receive event', event);
            this.currentScrollPosition = event;
        },
        scroll(value: number) {
            let top = Math.round(this.getHeight * value / 100); //- window.innerHeight;
            if (isMobile) {
                top = this.getHeight - top;
            }
            //console.log('top', top);

            if (top < 0 || value === 0) {
                top = 0;
                this.currentScrollPosition = 0;
            }

            window.scrollTo({
                left: 0,
                top: top,
                behavior: 'auto'
            });
        },
        toggleLeftBox() {
            if (this.fairSlug) {
                if (this.$route.name !== 'fairInfo') {
                    this.$router.push({name: 'fairInfo', params: {slug: this.fairSlug}});
                } else {
                    this.$router.push({name: 'fair', params: {slug: this.fairSlug}});
                }
            }
        },
    }
});
</script>

<style scoped>

</style>
